
import React from 'react';
import {GetApiUrl, GetApiUrlBase, GetSNSApiUrl, LOG_ENABLE, mobileOn} from '../URL/AppUrl';
import axios from 'axios';
import { FromBase64, IsValidS, IsValidV, ToBase64, replaceLine } from '../Util/Util';
import { LoginStoreInstance } from './LoginStore';
import {isMobile} from 'react-device-detect';

//빙고팡팡관련
class BingoStore {
  //observable변수

  constructor() {
  }

  clearAll = () =>
  {

  }
  
  //-------------------------------------------------------------------------------------------------------
  //빙고주문 목록읽기
  loadOrders = (pos, callback) => {

    const params = new URLSearchParams();

    params.append("rt", "0");
    params.append("pos", pos.toString());

    axios({
      method:"POST",
      url: GetApiUrl('adg/bplist/'),
      headers: {
        //Accept: 'application/json',
        Accept: 'application/text',
        'Content-Type': 'application/x-www-form-urlencoded;charset=UTF-8',
      },
      responseType: 'json', // 기본 값, [ 'arraybuffer', 'blob', 'document', 'json', 'text', 'stream' ]
      responseEncoding: 'utf8', // 기본 값
      data: params
    }).then((res)=>{
        this.parseOrderListResult(res.data, callback);
    }).catch(error=>{
        console.log(error);
        this.parseOrderListResult(null, callback);
    });
  }

  parseOrderListResult = (result, callback) => 
  {
    if (LOG_ENABLE)
      console.log(`parseOrderListResult ${result ? JSON.stringify(result) : 'unknown'}`);

    if (!IsValidV(result))
    {
      if (LOG_ENABLE)
        console.log("request failed.");

      if (callback)
      {
        callback(-2, null, null, null);
      }

      return;
    }
    
    if (callback)
    {
      if (IsValidS(result.list))
      {
        result.list.forEach(element => {
          this.decodeOrderSimple(element);
        });
      }

      if (result.ret === 100)
      {
        LoginStoreInstance.sessionError = true;
      }

      callback(result.ret, result.ft, result.tc, result.list);
    }
  }

  decodeOrderSimple = (item) =>
  {
    if (IsValidS(item.tt))
      item.tt = FromBase64(item.tt);

    if (IsValidS(item.wun))
      item.wun = FromBase64(item.wun);
  }
  //-------------------------------------------------------------------------------------------------------
  //광고명으로 검색
  searchOrders = (sw, callback) => {

    const params = new URLSearchParams();

    params.append("rt", "0");
    params.append("sw", ToBase64(sw));

    axios({
      method:"POST",
      url: GetApiUrl('adg/bpser/'),
      headers: {
        //Accept: 'application/json',
        Accept: 'application/text',
        'Content-Type': 'application/x-www-form-urlencoded;charset=UTF-8',
      },
      responseType: 'json', // 기본 값, [ 'arraybuffer', 'blob', 'document', 'json', 'text', 'stream' ]
      responseEncoding: 'utf8', // 기본 값
      data: params
    }).then((res)=>{
        this.parseSearchResult(res.data, callback);
    }).catch(error=>{
        console.log(error);
        this.parseSearchResult(null, callback);
    });
  }

  parseSearchResult = (result, callback) => 
  {
    if (LOG_ENABLE)
      console.log(`parseSearchResult ${result ? JSON.stringify(result) : 'unknown'}`);

    if (!IsValidV(result))
    {
      if (LOG_ENABLE)
        console.log("request failed.");

      if (callback)
      {
        callback(-2, null);
      }

      return;
    }
    
    if (callback)
    {
      if (IsValidS(result.list))
      {
        result.list.forEach(element => {
          this.decodeOrderSimple(element);
        });
      }

      if (result.ret === 100)
      {
        LoginStoreInstance.sessionError = true;
      }

      callback(result.ret, result.list);
    }
  }

  //-------------------------------------------------------------------------------------------------------
  //특정 주문의 상태값을 읽음
  getOrderStatus = (oidx, callback) => {

    const params = new URLSearchParams();

    params.append("rt", "2");
    params.append("oidx", oidx.toString());

    axios({
      method:"POST",
      url: GetApiUrl('adg/bpdet/'),
      headers: {
        //Accept: 'application/json',
        Accept: 'application/text',
        'Content-Type': 'application/x-www-form-urlencoded;charset=UTF-8',
      },
      responseType: 'json', // 기본 값, [ 'arraybuffer', 'blob', 'document', 'json', 'text', 'stream' ]
      responseEncoding: 'utf8', // 기본 값
      data: params
    }).then((res)=>{
        this.parseOrderStatusResult(res.data, callback);
    }).catch(error=>{
        console.log(error);
        this.parseOrderStatusResult(null, callback);
    });
  }

  parseOrderStatusResult = (result, callback) => 
  {
    if (LOG_ENABLE)
      console.log(`parseOrderStatusResult ${result ? JSON.stringify(result) : 'unknown'}`);

    if (!IsValidV(result))
    {
      if (LOG_ENABLE)
        console.log("request failed.");

      if (callback)
      {
        callback(-2, null);
      }

      return;
    }
    
    if (callback)
    {
      if (result.ret === 100)
      {
        LoginStoreInstance.sessionError = true;
      }
      
      if (LOG_ENABLE)
        console.log(`parseOrderStatusResult : callback`);

      callback(result.ret, result.info);
    }
  }
  //-------------------------------------------------------------------------------------------------------
  //특정 광고 한개의 상세 정보읽기
  getOrderDetail = (oidx, callback) => {

    const params = new URLSearchParams();

    params.append("rt", "0");
    params.append("oidx", oidx.toString());

    axios({
      method:"POST",
      url: GetApiUrl('adg/bpdet/'),
      headers: {
        //Accept: 'application/json',
        Accept: 'application/text',
        'Content-Type': 'application/x-www-form-urlencoded;charset=UTF-8',
      },
      responseType: 'json', // 기본 값, [ 'arraybuffer', 'blob', 'document', 'json', 'text', 'stream' ]
      responseEncoding: 'utf8', // 기본 값
      data: params
    }).then((res)=>{
        this.parseOrderDetailResult(res.data, callback);
    }).catch(error=>{
        console.log(error);
        this.parseOrderDetailResult(null, callback);
    });
  }

  //주문의 결제 관련 정보만 얻음
  getOrderPurchaseInfo = (oidx, callback) => {

    const params = new URLSearchParams();

    params.append("rt", "3");
    params.append("oidx", oidx.toString());

    axios({
      method:"POST",
      url: GetApiUrl('adg/bpdet/'),
      headers: {
        //Accept: 'application/json',
        Accept: 'application/text',
        'Content-Type': 'application/x-www-form-urlencoded;charset=UTF-8',
      },
      responseType: 'json', // 기본 값, [ 'arraybuffer', 'blob', 'document', 'json', 'text', 'stream' ]
      responseEncoding: 'utf8', // 기본 값
      data: params
    }).then((res)=>{
        this.parseOrderDetailResult(res.data, callback);
    }).catch(error=>{
        console.log(error);
        this.parseOrderDetailResult(null, callback);
    });
  }

  parseOrderDetailResult = (result, callback) => 
  {
    if (LOG_ENABLE)
      console.log(`parseOrderDetailResult ${result ? JSON.stringify(result) : 'unknown'}`);

    if (!IsValidV(result))
    {
      if (LOG_ENABLE)
        console.log("request failed.");

      if (callback)
      {
        callback(-2, null);
      }

      return;
    }
    
    if (callback)
    {
      if (IsValidV(result.info))
      {
        this.decodeOrderDetail(result.info);
      }

      if (result.ret === 100)
      {
        LoginStoreInstance.sessionError = true;
      }

      callback(result.ret, result.info);
    }
  }

  decodeOrderDetail = (item) =>
  {
    if (IsValidS(item.tt))
      item.tt = FromBase64(item.tt);

    if (IsValidS(item.gti) && item.gti !== "n")
      item.gti = FromBase64(item.gti);

    if (IsValidS(item.cn) && item.cn !== "n")
      item.cn = FromBase64(item.cn);

    if (IsValidS(item.wun) && item.wun !== "n")
      item.wun = FromBase64(item.wun);

    if (IsValidS(item.uif) && item.uif !== "n")
    {
      item.userInfo = JSON.parse(FromBase64(item.uif));
    }

    if (IsValidS(item.bi) && item.bi !== "n")
    {
      item.bankInfo = JSON.parse(FromBase64(item.bi));

      if (LOG_ENABLE)
        console.log(`order bank info : ${JSON.stringify(item.bankInfo)}`);
    }

    if (IsValidS(item.img) && item.img !== "n")
    {
      item.imgInfo = JSON.parse(FromBase64(item.img));
    }

    if (IsValidS(item.sif) && item.sif !== "n")
    {
      item.symbolInfo = JSON.parse(FromBase64(item.sif));

      if (LOG_ENABLE)
        console.log(`order symbol info : ${JSON.stringify(item.symbolInfo)}`);
    }

    if (IsValidS(item.llk) && item.llk !== "n")
      item.llk = FromBase64(item.llk);

    if (IsValidS(item.rlk) && item.rlk !== "n")
      item.rlk = FromBase64(item.rlk);

    //광고 추가데이터 파싱
    if (IsValidS(item.kw1) && item.kw1 !== "n") //심볼키워드1
      item.kw1 = FromBase64(item.kw1);

    if (IsValidS(item.kw2) && item.kw2 !== "n") //심볼키워드2
      item.kw2 = FromBase64(item.kw2);

    if (IsValidS(item.kw3) && item.kw3 !== "n") //심볼키워드3
      item.kw3 = FromBase64(item.kw3);

    if (IsValidS(item.dc) && item.dc !== "n") //요청사항
      item.dc = FromBase64(item.dc);
  }
  //-------------------------------------------------------------------------------------------------------
  //빙고 통계 정보 얻기
  getReport = (oidx, callback) => {

    const params = new URLSearchParams();

    params.append("rt", "5");
    params.append("oidx", oidx.toString());

    axios({
      method:"POST",
      url: GetApiUrl('adg/bpdet/'),
      headers: {
        //Accept: 'application/json',
        Accept: 'application/text',
        'Content-Type': 'application/x-www-form-urlencoded;charset=UTF-8',
      },
      responseType: 'json', // 기본 값, [ 'arraybuffer', 'blob', 'document', 'json', 'text', 'stream' ]
      responseEncoding: 'utf8', // 기본 값
      data: params
    }).then((res)=>{
        this.parseReportResult(res.data, callback);
    }).catch(error=>{
        console.log(error);
        this.parseReportResult(null, callback);
    });
  }

  parseReportResult = (result, callback) => 
  {
    if (LOG_ENABLE)
      console.log(`parseReportResult ${result ? JSON.stringify(result) : 'unknown'}`);

    if (!IsValidV(result))
    {
      if (LOG_ENABLE)
        console.log("request failed.");

      if (callback)
      {
        callback(-2, null);
      }

      return;
    }
    
    if (callback)
    {
      if (IsValidV(result.info))
      {
        this.decodeReport(result.info);
      }

      if (result.ret === 100)
      {
        LoginStoreInstance.sessionError = true;
      }

      callback(result.ret, result.info);
    }
  }

  decodeReport = (v) =>
  {
    if (IsValidS(v.ag) && v.ag !== "n")
    {
      v.ageInfo = JSON.parse(FromBase64(v.ag));
    }
    else
    {
      v.ageInfo = {
        mag10: 0,
        fag10: 0,
        mag20: 0,
        fag20: 0,
        mag30: 0,
        fag30: 0,
        mag40: 0,
        fag40: 0,
        mag50: 0,
        fag50: 0
      };
    }

    if (IsValidS(v.pl1) && v.pl1 !== "n")
    {
      v.playInfo1 = JSON.parse(FromBase64(v.pl1));
    }
    else
    {
      v.playInfo1 = {
        p1: 0,
        p2: 0,
        p3: 0,
        p4: 0,
        p5: 0,
      };
    }

    if (IsValidS(v.pl2) && v.pl2 !== "n")
    {
      v.playInfo2 = JSON.parse(FromBase64(v.pl2));
    }
    else
    {
      v.playInfo2 = {
        p6: 0,
        p7: 0,
        p8: 0,
        p9: 0,
        p10: 0,
        p11: 0,
        p12: 0,
        p13: 0,
        p14: 0,
        p15: 0,
      };
    }

    if (IsValidS(v.pl3) && v.pl3 !== "n")
    {
      v.playInfo3 = JSON.parse(FromBase64(v.pl3));
    }
    else
    {
      v.playInfo2 = {
        p16: 0,
        p17: 0,
        p18: 0,
        p19: 0,
        p20: 0,
        p21: 0,
        p22: 0,
        p23: 0,
        p24: 0,
        p25: 0,
      };
    }
  }
  //-------------------------------------------------------------------------------------------------------
  //새로운 광고주문하기
  addNewOrder = (gc, bn, ag, gt, rdt, un, uph, em, pm, wun, callback) => {

    const params = new URLSearchParams();

    params.append("rt", "0");
    params.append("gc", gc.toString());
    params.append("bn", ToBase64(bn));
    params.append("ag", ag.toString());
    params.append("gt", gt.toString());
    params.append("rdt", rdt.toString());
    params.append("un", ToBase64(un));
    params.append("uph", uph);
    params.append("em", em);
    params.append("pm", pm.toString());
    params.append("wun", ToBase64(wun));
    params.append("mb", isMobile ? "1" : "0"); //신규추가 파라메터, PC인지 모바일인지에 따라서 결제 루틴이 달라지므로, 반드시 포함필요

    axios({
      method:"POST",
      url: GetApiUrl('adg/bpadd/'),
      headers: {
        //Accept: 'application/json',
        Accept: 'application/text',
        'Content-Type': 'application/x-www-form-urlencoded;charset=UTF-8',
      },
      responseType: 'json', // 기본 값, [ 'arraybuffer', 'blob', 'document', 'json', 'text', 'stream' ]
      responseEncoding: 'utf8', // 기본 값
      data: params
    }).then((res)=>{
        this.parseAddResult(res.data, callback);
    }).catch(error=>{
        console.log(error);
        this.parseAddResult(null, callback);
    });
  }

  //재주문하기
  addReOrder = (oidx, pm, wun, rdt, bc, callback) => {

    const params = new URLSearchParams();

    params.append("rt", "1");
    params.append("oidx", oidx.toString());
    params.append("pm", pm.toString());
    params.append("wun", ToBase64(wun));
    params.append("rdt", rdt.toString());
    params.append("bc", bc.toString());
    params.append("mb", isMobile ? "1" : "0"); //신규추가 파라메터, PC인지 모바일인지에 따라서 결제 루틴이 달라지므로, 반드시 포함필요
    
    if (LOG_ENABLE)
      console.log(`addReOrder : ${params.toString()}`);

    axios({
      method:"POST",
      url: GetApiUrl('adg/bpadd/'),
      headers: {
        //Accept: 'application/json',
        Accept: 'application/text',
        'Content-Type': 'application/x-www-form-urlencoded;charset=UTF-8',
      },
      responseType: 'json', // 기본 값, [ 'arraybuffer', 'blob', 'document', 'json', 'text', 'stream' ]
      responseEncoding: 'utf8', // 기본 값
      data: params
    }).then((res)=>{
        this.parseAddResult(res.data, callback);
    }).catch(error=>{
        console.log(error);
        this.parseAddResult(null, callback);
    });
  }

  parseAddResult = (result, callback) => 
  {
    if (LOG_ENABLE)
      console.log(`parseAddResult ${result ? JSON.stringify(result) : 'unknown'}`);

    if (!IsValidV(result))
    {
      if (LOG_ENABLE)
        console.log("request failed.");

      if (callback)
      {
        callback(-2, null, null);
      }

      return;
    }
    
    if (callback)
    {
      if (IsValidV(result.info))
      {
        this.decodeOrderDetail(result.info);
      }

      if (result.ret === 100)
      {
        LoginStoreInstance.sessionError = true;
      }

      callback(result.ret, result.kicc, result.oidx);
    }
  }
  //-----------------------------------------------------------------------------------------------------------------------------
  //상세1단계수정
  //oidx: 광고 고유번호
  //pt: 상품분류
  //lbt, rbt, llk, rlk: 왼버튼/오른 버튼 기능 및 링크주소
  editStep1 = (oidx, pt, lbt, rbt, llk, rlk, callback) => {

    const params = new URLSearchParams();

    params.append("rt", "0");
    params.append("oidx", oidx.toString());
    params.append("pt", pt.toString());
    params.append("lbt", lbt.toString());
    params.append("rbt", rbt.toString());
    params.append("llk", ToBase64(llk));
    params.append("rlk", ToBase64(rlk));
    
    if (LOG_ENABLE)
      console.log(`editStep1 : ${params.toString()}`);

    axios({
      method:"POST",
      url: GetApiUrl('adg/bpedit/'),
      headers: {
        //Accept: 'application/json',
        Accept: 'application/text',
        'Content-Type': 'application/x-www-form-urlencoded;charset=UTF-8',
      },
      responseType: 'json', // 기본 값, [ 'arraybuffer', 'blob', 'document', 'json', 'text', 'stream' ]
      responseEncoding: 'utf8', // 기본 값
      data: params
    }).then((res)=>{
        this.parseStep1Result(res.data, callback);
    }).catch(error=>{
        console.log(error);
        this.parseStep1Result(null, callback);
    });
  }

  parseStep1Result = (result, callback) => 
  {
    if (LOG_ENABLE)
      console.log(`parseStep1Result ${result ? JSON.stringify(result) : 'unknown'}`);

    if (!IsValidV(result))
    {
      if (LOG_ENABLE)
        console.log("request failed.");

      if (callback)
      {
        callback(-2);
      }

      return;
    }
    
    if (callback)
    {
      if (result.ret === 100)
      {
        LoginStoreInstance.sessionError = true;
      }

      callback(result.ret);
    }
  }
  //-----------------------------------------------------------------------------------------------------------------------------
  //상세2단계수정
  //vos : 영상제작 아웃소싱여부
  //dt: 디자인타입
  //bn: 게임 타이틀
  //mb : 로비 배너
  //sy: 심볼 참조 자료
  //gb: 게임내 배너 참조 자료
  //vd: 첨부 영상
  //gc: 선물개수
  //vos: 영상제작 요청
  //kw1, kw2, kw3: 심볼 키워드 1,2,3
  //dc: 요청사항
  editStep2 = (oidx, dt, bn, mb, sy, gb, vd, gc, vos, kw1, kw2, kw3, dc, callback) => {

    const params = new URLSearchParams();

    params.append("rt", "1");
    params.append("oidx", oidx.toString());
    params.append("dt", dt.toString());
    params.append("bn", ToBase64(bn));

    params.append("mb", mb);
    params.append("sy", sy);
    params.append("gb", gb);
    params.append("vd", vd);
    params.append("gc", gc.toString());
    params.append("vos", vos.toString());
    params.append("kw1", ToBase64(kw1));
    params.append("kw2", ToBase64(kw2));
    params.append("kw3", ToBase64(kw3));
    params.append("dc", ToBase64(dc));
    
    if (LOG_ENABLE)
      console.log(`editStep2 : ${params.toString()}`);

    axios({
      method:"POST",
      url: GetApiUrl('adg/bpedit/'),
      headers: {
        //Accept: 'application/json',
        Accept: 'application/text',
        'Content-Type': 'application/x-www-form-urlencoded;charset=UTF-8',
      },
      responseType: 'json', // 기본 값, [ 'arraybuffer', 'blob', 'document', 'json', 'text', 'stream' ]
      responseEncoding: 'utf8', // 기본 값
      data: params
    }).then((res)=>{
        this.parseStep2Result(res.data, callback);
    }).catch(error=>{
        console.log(error);
        this.parseStep2Result(null, callback);
    });
  }

  parseStep2Result = (result, callback) => 
  {
    if (LOG_ENABLE)
      console.log(`parseStep2Result ${result ? JSON.stringify(result) : 'unknown'}`);

    if (!IsValidV(result))
    {
      if (LOG_ENABLE)
        console.log("request failed.");

      if (callback)
      {
        callback(-2);
      }

      return;
    }
    
    if (callback)
    {
      if (result.ret === 100)
      {
        LoginStoreInstance.sessionError = true;
      }

      callback(result.ret);
    }
  }
  //-----------------------------------------------------------------------------------------------------------------------------
  //주문 등록요청
  registerOrder = (oidx, callback) => {

    const params = new URLSearchParams();

    params.append("rt", "2");
    params.append("oidx", oidx.toString());

    axios({
      method:"POST",
      url: GetApiUrl('adg/bpedit/'),
      headers: {
        //Accept: 'application/json',
        Accept: 'application/text',
        'Content-Type': 'application/x-www-form-urlencoded;charset=UTF-8',
      },
      responseType: 'json', // 기본 값, [ 'arraybuffer', 'blob', 'document', 'json', 'text', 'stream' ]
      responseEncoding: 'utf8', // 기본 값
      data: params
    }).then((res)=>{
        this.parseRegisterResult(res.data, callback);
    }).catch(error=>{
        console.log(error);
        this.parseRegisterResult(null, callback);
    });
  }

  parseRegisterResult = (result, callback) => 
  {
    if (LOG_ENABLE)
      console.log(`parseRegisterResult ${result ? JSON.stringify(result) : 'unknown'}`);

    if (!IsValidV(result))
    {
      if (LOG_ENABLE)
        console.log("request failed.");

      if (callback)
      {
        callback(-2, null);
      }

      return;
    }
    
    if (callback)
    {
      if (result.ret === 100)
      {
        LoginStoreInstance.sessionError = true;
      }

      callback(result.ret, result);
    }
  }
  //-----------------------------------------------------------------------------------------------------------------------------
  //승인대기 상태의 주문을 승인 또는 재작업 요청한다.
  //rt : 승인(3), 재작업(4)
  acceptOrder = (rt, oidx, callback) => {

    const params = new URLSearchParams();

    params.append("rt", rt.toString());
    params.append("oidx", oidx.toString());

    axios({
      method:"POST",
      url: GetApiUrl('adg/bpedit/'),
      headers: {
        //Accept: 'application/json',
        Accept: 'application/text',
        'Content-Type': 'application/x-www-form-urlencoded;charset=UTF-8',
      },
      responseType: 'json', // 기본 값, [ 'arraybuffer', 'blob', 'document', 'json', 'text', 'stream' ]
      responseEncoding: 'utf8', // 기본 값
      data: params
    }).then((res)=>{
        this.parseAcceptResult(res.data, callback);
    }).catch(error=>{
        console.log(error);
        this.parseAcceptResult(null, callback);
    });
  }

  parseAcceptResult = (result, callback) => 
  {
    if (LOG_ENABLE)
      console.log(`parseAcceptResult ${result ? JSON.stringify(result) : 'unknown'}`);

    if (!IsValidV(result))
    {
      if (LOG_ENABLE)
        console.log("request failed.");

      if (callback)
      {
        callback(-2, 0, 0);
      }

      return;
    }
    
    if (callback)
    {
      if (result.ret === 100)
      {
        LoginStoreInstance.sessionError = true;
      }

      callback(result.ret, result.oidx, result.st, result.sdt);
    }
  }
}

const BingoStoreInstance = new BingoStore();
const BingoStoreContext = React.createContext(BingoStoreInstance);

const UseBingoStore = () => React.useContext(BingoStoreContext);

export {UseBingoStore, BingoStoreInstance};
