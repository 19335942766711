import axios from "axios";
import { APP_MODE, GetApiUrl, GetUploadApiUrl, LOG_ENABLE } from "../URL/AppUrl";
import { saveAs } from 'file-saver';
import CryptoJS from 'crypto-js';
import imageCompression from 'browser-image-compression';
import JSZip from "jszip"

const defaultKey_hmac = "dGdrefw32fJd37#97fjedEpsdt9G2GDF";
const BPM_CARD = 0;
const BPM_VBANK = 1;
const BPM_TRANS = 2;
const BPM_CARD_PONE = 3; //포트원 카드결제
const BPM_VBANK_PONE = 4; //포트원 가상계좌결제

const SNSPM_TRANS = 0; //sns포인트결제 계좌이체
const SNSPM_CARD = 1; //sns포인트결제 카드
const SNSPM_VBANK = 2; //sns포인트결제 가상계좌
const SNSPM_CARD_PONE = 3; //sns포인트결제 포트원 카드
const SNSPM_VBANK_PONE = 4; //sns포인트결제 포트원 가상계좌

function NumberFormatString(num)
{
  if (num < 10)
    return "0" + num;

  return num.toString();
}

//세자리 마다 콤마 붙이기
function GetFormattedNum(num)
{
  if (isNaN(num))
    return "";

  return num.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",");
}

//이미지를 블롭으로 다운로드
const DownloadImageBlob = async (url) =>
{
  try
  {
    if (LOG_ENABLE)
      console.log(`download file : ${url}`);

    /*const file = await fetch(url, {
      method: 'get',
      headers: {
        Accept: 'image/*',
      },
    });

    if (file)
    {
      if (LOG_ENABLE)
      {
        if (!file.ok)
        {
          console.log(`DownloadImageToFile failed : ${JSON.stringify(file)}, ok : ${file.ok}`);
          return null;
        }
        else
          console.log(`DownloadImageToFile success : ${JSON.stringify(file)}, ok : ${file.ok}`);
      }

      //const v = await file.blob();
      const v = await file.arrayBuffer();

      if (LOG_ENABLE)
        console.log(`image blob info : ${JSON.stringify(v)}`);

      //saveAs(v, "test.jpg");

      return v;
    }
    else
    {
      if (LOG_ENABLE)
        console.log(`DownloadImageToFile failed!!!`);

      return null;
    }*/
    /*return new Promise((resolve, reject) => {
      axios({
        url: url,
        method: 'GET',
        maxBodyLength: Infinity,
        withCredentials: false,
        responseType: 'arraybuffer',
        timeout: 30000,
        //transformResponse: [],
        headers: {
          //Accept: 'application/json',
          Accept: 'image/*',
        },
      }).then((res)=>{
        if (!res || !res.data)
        {
          if (LOG_ENABLE)
            console.log(`DownloadImageToFile failed1`);

          reject('failed download');
        }
        else
        {
          if (LOG_ENABLE)
            console.log(`DownloadImageToFile success : ${JSON.stringify(res)}`);
          resolve(new Blob([res.data]));
        }

      }).catch(error=>{
        reject(error);
      });
    });*/

    /*const res = await axios.get("https://byby.dev/js-fetch-get-response-body", { 
      responseType: "blob",
      headers: {
        //Accept: 'application/json',
        Accept: 'image/*',
      },
      transformResponse: x => x
    });

    if (LOG_ENABLE)
      console.log(`DownloadImageToFile success : ${JSON.stringify(res)}`);

    const blob = new Blob([res.data]);

    return blob;*/

    return new Promise((resolve, reject) => {
      var xhr = new XMLHttpRequest();
      xhr.open("GET", url, true);
      //xhr.setRequestHeader('Content-type', 'application/json')
      xhr.responseType = 'blob';
      
      xhr.onload = function() {
        console.log(xhr);

        if (xhr.status === 200)
        {
          let blob = xhr.response;

          if (LOG_ENABLE)
            console.log(blob);

          resolve(blob);
        }
        else
        {
          reject('failed down load');
        }
      }
      
      xhr.send();
    });
  }
  catch(e)
  {
    if (LOG_ENABLE)
        console.log(`DownloadImageToFile failed2 : ${e.toString()}`);

    return null;
  }
}

function ConvertImageBlobToFile(imageBlob, fileName)
{
  try
  {
    if (APP_MODE === 0)
      console.log(`given blob : ${JSON.stringify(imageBlob)}`);

    const v = new File(imageBlob, fileName, {type : imageBlob.type, lastModified: new Date().getTime()});

    if (APP_MODE === 0)
      console.log(`given blob : ${JSON.stringify(v)}`);

    return v;
    //return 
    /*imageBlob.lastModifiedDate = new Date();
    imageBlob.name = fileName;

    if (APP_MODE === 0)
      console.log(`given blob : ${JSON.stringify(imageBlob)}`);

    return imageBlob;*/
  }
  catch(e)
  {
    if (LOG_ENABLE)
        console.log(`ConvertImageBlobToFile failed : ${e.toString()}`);

    return null;
  }
}

//파일객체의 이미지 크기를 수정
async function ResizeImage(imageFile, fileName, maxFileSize, maxImageSize, fileType)
{
  try
  {
    const options = {
      maxSizeMB: maxFileSize,
      maxWidthOrHeight: maxImageSize,
      fileType: fileType,
      useWebWorker: true,
    };

    const temp = await imageCompression(imageFile, options);

    if (!temp)
    {
      if (LOG_ENABLE)
      console.log(`ResizeImage failed1 : compression failed.`);

      return null;
    }
    else if (LOG_ENABLE)
      console.log(`image compression succeeded : ${temp}`);

    //return ConvertImageBlobToFile(temp, fileName, temp.type)
    return temp;
  }
  catch(e)
  {
    if (LOG_ENABLE)
      console.log(`ResizeImage failed : ${e.toString()}`);

    return null;
  }
}

function DownloadFile(url, fileName, logPrinter = null)
{
  try
  {
    if (LOG_ENABLE)
      console.log(`download file : ${url} => ${fileName}`);

    /*axios({
      url: url,
      method: 'GET',
      withCredentials: false,
      responseType: 'blob',
      data: ""
    })
    .then((response) => {
      const url = window.URL.createObjectURL(new Blob([response.data]));
          const link = document.createElement('a');
          link.href = url;
          link.setAttribute('download', fileName);
          document.body.appendChild(link);
          link.click();
          document.body.removeChild(link);
    })
    .catch(error=>{

      if (logPrinter)
        logPrinter(error);
      else
        console.log(error);
        
      alert(fileName + " 파일을 다운로드중 오류가 발생했습니다.");
    });*/

    saveAs(url, fileName);
  }
  catch(e)
  {
    alert(e.toString());
  }
}

function UploadFileWithExt(uploadType, file, ext, callback)
{
  const formData = new FormData();

  formData.append("file", file);
  formData.append("rt", uploadType.toString());

  if (IsValidS(ext))
  {
    formData.append("ext", ext);
  }

  axios({
    method:"POST",
    url:  GetUploadApiUrl("upload/up1/"),
    headers: {
      Accept: 'application/json',
      'Content-Type': 'multipart/form-data',
    },
    responseType: 'json', // 기본 값, [ 'arraybuffer', 'blob', 'document', 'json', 'text', 'stream' ]
    responseEncoding: 'utf8', // 기본 값
    data: formData
  })
  .then((res)=>{

    if (LOG_ENABLE)
    {
      console.log("upload done");
      console.log(JSON.stringify(res.data));
    }

    //결과값은 결과 코드와, 성공일경우 업로드된 서버사운드파일이름이다.
    if (!IsValidV(res.data) || res.data.ret === undefined || res.data.ret !== 0)
    {
      if (LOG_ENABLE)
        console.log("invalid result");

      callback(false, null, null);
      return;
    }
    else //업로드에 성공했다.
    {
      callback(true, res.data.of, res.data.nf);
    }
  })
  .catch(error=>{

    if (LOG_ENABLE)
      console.log(error);

    callback(false, null, null);
  });
}
//각종 파일을 업로드한다.
//uploadType : SNS플라이사업자등록증(0), 머니팡 프로필이미지(1), SMS플라이 웹사이트 이미지(2), 머니팡 상품이미지(3), 머니팡 문의내역이미지(4), 빙고이미지(11)
function UploadFile(uploadType, file, callback)
{
  UploadFileWithExt(uploadType, file, null, callback);
}

//url을 통해서 서버에 파일 업로드 요청
async function UploadFileUrl(url)
{

  return new Promise((resolve, reject) => {
    const params = new URLSearchParams();

    params.append("lk", url);
  
    axios({
      method:"POST",
      url:  GetUploadApiUrl("upload/dn/"),
      headers: {
        Accept: 'application/text',
          'Content-Type': 'application/x-www-form-urlencoded;charset=UTF-8',
      },
      responseType: 'json', // 기본 값, [ 'arraybuffer', 'blob', 'document', 'json', 'text', 'stream' ]
      responseEncoding: 'utf8', // 기본 값
      data: params
    })
    .then((res)=>{
  
      if (APP_MODE === 0)
        console.log(`UploadFileUrl : ${JSON.stringify(res.data)}`);

      //결과값은 결과 코드와, 성공일경우 업로드된 서버사운드파일이름이다.
      if (!IsValidV(res.data) || !IsValidV(res.data.ret))
      {
        if (LOG_ENABLE)
          console.log("UploadFileUrl failed");
          
        reject("failed upload img");
      }
      else //업로드에 성공했다.
      {
        resolve(res.data.nf);
      }
    })
    .catch(error=>{
  
      if (LOG_ENABLE)
        console.log(error);
  
      reject(error);
    });
  });
}

function SaveMultiFileToZip(fileList, zipFileName, callback)
{
  try
  {
    if (!IsValidS(fileList))
    {
      callback(false);
      return;
    }
  
    let valid = false;
  
    for (let i = 0;i < fileList.length; ++i)
    {
      if (IsValidV(fileList[i].data) || IsValidV(fileList[i].blob))
      {
        valid = true;
        break;
      }
    }
  
    if (!valid)
    {
      callback(false);
      return;
    }
  
    const zip = new JSZip(); // ZIP 객체 생성
  
    for (let i = 0;i < fileList.length; ++i)
    {
      if (IsValidV(fileList[i].data) || IsValidV(fileList[i].blob))
      {
        if (fileList[i].blob)
          zip.file(fileList[i].name, fileList[i].blob);
        else
          zip.file(fileList[i].name, new Blob([fileList[i].data]));
      }
    }
  
    zip.generateAsync({type:"blob"})
    .then(function (blob) {
      const url = window.URL.createObjectURL(blob);
      const link = document.createElement('a');
      link.href = url;
      link.setAttribute('download', zipFileName);
      document.body.appendChild(link);
      link.click();
      document.body.removeChild(link);
    });

    callback(true);
  }
  catch(e)
  {
    if (LOG_ENABLE)
      console.log(`SaveMultiFileToZip failed : ${e.toString()}`);

    callback(false);
  }
}

function ToBase64(v)
{
  if (v === undefined || v=== null || v.length < 1)
    return v;

  return Buffer.from(v, "utf8").toString("base64");
}

function FromBase64(v)
{
  if (v === undefined || v=== null || v.length < 1)
    return v;

  return Buffer.from(v, "base64").toString("utf-8");
}

function IsValidS(v)
{
  return v !== undefined && v !== null && v.length > 0;
}

function IsValidV(v)
{
  return v !== undefined && v !== null;
}

//사업자 번호를 000-00-00000 형식으로 얻음
function GetFormattedCNum(cnum)
{
  if (!IsValidS(cnum))
    return "";

  if (cnum.length !== 10)
    return cnum;

  return cnum.substring(0, 3) + "-" + cnum.substring(3, 5) + "-" + cnum.substring(5, 10);
}

//사업자 번호를 000-00-00000 형식으로 얻음
function GetFormattedCNum2(cnum)
{
  if (!IsValidS(cnum))
    return "";

  let temp = cnum;

  if (temp.indexOf('-') >= 0)
  {
    temp = temp.replace(/-/gi, '');
  }

  if (isNaN(temp))
  {
    return cnum;
  }
  
  if (temp.length < 3)
  {  
    return temp;
  }

  let result = temp.substring(0, 3) + '-';

  if (temp.length >= 5)
  {
    result += temp.substring(3, 5) + '-';
  }
  else
  {
    return result + temp.substring(3, temp.length);
  }

  return result + temp.substring(5, temp.length);
}

//전화 번호를 000-0000-0000 형식으로
//051-234-1111
function GetFormattedPhone(phone)
{
  if (!IsValidS(phone))
    return "";

  if (phone.length === 8)
  {
    return phone.substring(0, 4) + "-" + phone.substring(4, 8);
  }
  if (phone.length === 9)
  {
    return phone.substring(0, 2) + "-" + phone.substring(2, 5) + "-" + phone.substring(5, 9);
  }
  if (phone.length === 10)
  {
    if (phone.indexOf("02") === 0)
      return phone.substring(0, 2) + "-" + phone.substring(2, 6) + "-" + phone.substring(6, 10);

    return phone.substring(0, 3) + "-" + phone.substring(3, 6) + "-" + phone.substring(6, 10);
  }
  else if (phone.length === 11)
  {
    return phone.substring(0, 3) + "-" + phone.substring(3, 7) + "-" + phone.substring(7, 11);
  }
  else if (phone.length === 12)
  {
    return phone.substring(0, 4) + "-" + phone.substring(4, 8) + "-" + phone.substring(8, 12);
  }

  return phone;
}

function GetFormattedPhone2(phone)
{
  if (!IsValidS(phone))
    return "";

  let temp = phone;

  if (temp.indexOf("-") >= 0)
  {
    temp = temp.replace(/-/gi, "");
  }

  if (temp.length < 3)
  {
    if (temp.indexOf("02") === 0)
    {
      return temp.replace("02", "02-");
    }

    return phone;
  }

  if (temp.length >= 3)
  {
    if (temp.length >= 4)
    {
      if (temp.indexOf("0505") === 0)
      {
        let temp2 = temp.substring(0, 4);
        temp2 += "-";
  
        let lg = temp.length - 4;
  
        if (temp.length > 4)
        {
          if (lg < 4)
          {
            temp2 += temp.substring(4, temp.length);

            return temp2;
          }
          else if (lg <= 7)
          {
            temp2 += temp.substring(4, 7);
            temp2 += "-";
            temp2 += temp.substring(7, temp.length);
  
            return temp2;
          }
          
          temp2 += temp.substring(4, 8);
          temp2 += "-";
          temp2 += temp.substring(8, temp.length);
          return temp2;
        }
  
        if (temp.length === 4)
          return temp2;
  
        temp2 += temp.substring(4, temp.length);
  
        return temp2;
      }
      else if (temp.indexOf("1566") === 0 || temp.indexOf("1588") === 0 || temp.indexOf("1600") === 0 || temp.indexOf("1670") === 0 ||
      temp.indexOf("1577") === 0 || temp.indexOf("1899") === 0 || temp.indexOf("1522") === 0 || temp.indexOf("1544") === 0 || temp.indexOf("1644") === 0 ||
      temp.indexOf("1661") === 0 || temp.indexOf("1599") === 0 || temp.indexOf("1688") === 0 ||  temp.indexOf("1666") === 0 ||  temp.indexOf("1855") === 0 || 
      temp.indexOf("1811") === 0 || temp.indexOf("1877") === 0)
      {
        let temp2 = temp.substring(0, 4);
        temp2 += "-";
  
        if (temp.length === 4)
          return temp2;
  
        temp2 += temp.substring(4, temp.length);
  
        return temp2;
      }
    }

    if (temp.indexOf("010") === 0 || temp.indexOf("011") === 0 || temp.indexOf("012") === 0 || temp.indexOf("016") === 0 || temp.indexOf("017") === 0 || 
      temp.indexOf("018") === 0 || temp.indexOf("019") === 0 || temp.indexOf("020") === 0 || temp.indexOf("030") === 0 || 
      temp.indexOf("040") === 0 || temp.indexOf("050") === 0 || temp.indexOf("060") === 0 || temp.indexOf("070") === 0 || 
      temp.indexOf("080") === 0 || temp.indexOf("090") === 0 || temp.indexOf("051") === 0 || temp.indexOf("053") === 0 || temp.indexOf("032") === 0 ||
      temp.indexOf("062") === 0 || temp.indexOf("042") === 0 || temp.indexOf("052") === 0 || temp.indexOf("044") === 0 || temp.indexOf("031") === 0 ||
      temp.indexOf("033") === 0 || temp.indexOf("043") === 0 || temp.indexOf("041") === 0 || temp.indexOf("063") === 0 || temp.indexOf("061") === 0 ||
      temp.indexOf("054") === 0 || temp.indexOf("055") === 0 || temp.indexOf("064") === 0)
    {
      let temp2 = temp.substring(0, 3);

      temp2 += "-";

      let lg = temp.length - 3;

      if (temp.length > 4)
      {
        if (lg < 4)
        {
          temp2 += temp.substring(3, temp.length);

          return temp2;
        }
        else if (lg <= 7)
        {
          temp2 += temp.substring(3, 6);
          temp2 += "-";
          temp2 += temp.substring(6, temp.length);

          return temp2;
        }
        
        temp2 += temp.substring(3, 7);
        temp2 += "-";
        temp2 += temp.substring(7, temp.length);
        return temp2;
      }

      if (temp.length === 3)
        return temp2;

      temp2 += temp.substring(3, temp.length);

      return temp2;
    }
    else if (temp.indexOf("02") === 0)
    {
      let temp2 = temp.substring(0, 2);

      temp2 += "-";

      let lg = temp.length - 2;

      if (temp.length > 4)
      {
        if (lg < 4)
        {
          temp2 += temp.substring(2, temp.length);

          return temp2;
        }
        else if (lg <= 7)
        {
          temp2 += temp.substring(2, 5);
          temp2 += "-";
          temp2 += temp.substring(5, temp.length);

          return temp2;
        }
        
        temp2 += temp.substring(2, 6);
        temp2 += "-";
        temp2 += temp.substring(6, temp.length);
        return temp2;
      }

      if (temp.length === 2)
        return temp2;

      temp2 += temp.substring(2, temp.length);

      return temp2;
    }
  }
  
  return phone;
}

function GetChatTime(dateInfo)
{
  if (!IsValidS(dateInfo))
  return "";
  
  let temp = dateInfo.split(" ");

  if (temp.length < 2)
  {
    return dateInfo;
  }

  temp = temp[1].split(":");

  if (temp.length < 3)
    return dateInfo;

  if (parseInt(temp[0]) < 12)
  {
    return `오전 ${temp[0]}:${temp[1]}`;
  }
  else
  {
    return `오후 ${temp[0]}:${temp[1]}`;
  }
}

function GetDataTime(dateInfo)
{
  if (!IsValidS(dateInfo))
  return "";
  
  let temp = dateInfo.split(" ");

  if (temp.length < 2)
  {
    return dateInfo;
  }

  temp = temp[1].split(":");

  if (temp.length < 3)
    return dateInfo;

  return `${temp[0]}:${temp[1]}:${temp[2]}`;
}

function GetDataDate(dateInfo)
{
  if (!IsValidS(dateInfo))
  return "";
  
  let temp = dateInfo.split(" ");

  if (temp.length < 2)
  {
    return dateInfo;
  }

  return temp[0];
}

function GetDataDateAndTime(dateInfo)
{
  if (!IsValidS(dateInfo))
  return "";
  
  let temp = dateInfo.split(" ");

  if (temp.length < 2)
  {
    return dateInfo;
  }

  let temp2 = temp[1].split(":");

  if (temp2.length < 3)
  {
    return dateInfo;
  }

  return `${temp[0]} ${temp2[0]}:${temp2[1]}`;
}

//yyyymmdd형식의 숫자 일자정보를 YYYY-MM-DD형식으로 변경
function ConvertDateInfo(dateInfo)
{
  if (!IsValidV(dateInfo))
    return "";

  let date = dateInfo.toString();

  if (date.length < 8)
    return date;

  return `${date.substring(0, 4)}-${date.substring(4, 6)}-${date.substring(6, 8)}`;
}

//yyyymm형식의 숫자 일자정보를 YYYY-MM형식으로 변경
function ConvertSerialMonthInfo(dateInfo)
{
  if (!IsValidV(dateInfo))
    return "";

  let date = dateInfo.toString();

  if (date.length < 6)
    return date;

  return `${date.substring(0, 4)}-${date.substring(4, 6)}`;
}

function isImageFile(name)
{
  if (!IsValidS(name))
    return false;

  try
  {
    const t = name.toUpperCase();

    if (t.indexOf(".PNG") >= 0 || t.indexOf(".JPG") >= 0 || t.indexOf(".JPEG") >= 0 || t.indexOf(".BMP") >= 0 || t.indexOf(".GIF") >= 0 || t.indexOf(".TIF") >= 0)
      return true;

    return false;
  }
  catch(e)
  {
    return false;
  }
}

//refDate가 0일경우 오늘자 년도를, 그렇지 않을경우 refDate의 년도를 얻는다. refDate는 YYYYMMDD형식의 정수 값이다.
function GetDateYear(refDate)
{
  if (refDate === 0)
  {
    return (new Date()).getFullYear();
  }

  const temp = refDate.toString();

  if (temp.length !== 8)
  {
    return (new Date()).getFullYear();
  }

  return parseInt(temp.substring(0, 4));
}

//refDate가 0일경우 오늘자 월을, 그렇지 않을경우 refDate의 월 얻는다. refDate는 YYYYMMDD형식의 정수 값이다.
function GetDateMonth(refDate)
{
  if (refDate === 0)
  {
    return (new Date()).getMonth() + 1;
  }

  const temp = refDate.toString();

  if (temp.length !== 8)
  {
    return (new Date()).getMonth() + 1;
  }

  return parseInt(temp.substring(4, 6));
}

//refDate가 0일경우 오늘자 일을, 그렇지 않을경우 refDate의 일을 얻는다. refDate는 YYYYMMDD형식의 정수 값이다.
function GetDateDay(refDate)
{
  if (refDate === 0)
  {
    return (new Date()).getDate();
  }

  const temp = refDate.toString();

  if (temp.length !== 8)
  {
    return (new Date()).getDate();
  }

  return parseInt(temp.substring(6, 8));
}

function GetValueOnly(v, rep)
{
  if (!IsValidS(v) || v === "n")
    return rep;

  return v;
}

//오늘부터 day만큼 경과일 일자를 YYYYMMDD형식으로 구함
const GetNextDateFormat2 = (days) => {

  const nowDate = new Date();

  nowDate.setDate(nowDate.getDate() + days);

  return `${nowDate.getFullYear()}${GetFormattedNum(nowDate.getMonth() + 1)}${GetFormattedNum(nowDate.getDate())}`;
}

//오늘자 년도를 구함
function GetCurrentYear()
{
  const nowDate = new Date();

  return nowDate.getFullYear();
}

//오늘자 월을 구함
function GetCurrentMonth()
{
  const nowDate = new Date();

  return nowDate.getMonth() + 1;
}

//오늘자 일을 구함
function GetCurrentDay()
{
  const nowDate = new Date();

  return nowDate.getDate();
}

//주어진 일자의 이전 월을 구함
function GetPrevMonth(year, month)
{
  if (month > 1)
  {
    return {year : year, month : month - 1};
  }

  return {year : year - 1, month : 12};
}

//주어진 일자의 다음 월을 구함
function GetNextMonth(year, month)
{
  if (month < 12)
  {
    return {year : year, month : month + 1};
  }

  return {year : year + 1, month : 1};
}

//해당 월의 마지막 날짜를 구한다.
function GetLastDate(year, month)
{
  const date = new Date(year, month, 0);

  return date.getDate();
}

//yyyy-mm-01 ~ yyyy-mm-31 같은 형식의 날짜 범위를 구한다.
function GetMonthRage(year, month, isCurrent)
{
  if (isCurrent)
  {
    return `${year}-${NumberFormatString(month)}-01~${year}-${NumberFormatString(month)}-${NumberFormatString(GetCurrentDay())}`;
  }
  
  return `${year}-${NumberFormatString(month)}-01~${year}-${NumberFormatString(month)}-${GetLastDate(year, month)}`;
}

//YYYYMMDD형식의 일자를 Date형식으로 변경함
function GetSerialDateToDate(sd)
{
  try
  {
    const temp = sd.toString();

    if (temp.length !== 8)
      return new Date();
  
    return new Date(parseInt(temp.substring(0, 4)), parseInt(temp.substring(4, 6))-1, parseInt(temp.substring(6, 8)), 0, 0, 0, 0);
  }
  catch(e)
  {
    console.log(e.toString());
    return new Date();
  }
}

//YYYYMMDD형식의 일자를 Date형식으로 변경함
function GetSerialDateToDate2(sd, hour, minute, second)
{
  try
  {
    const temp = sd.toString();

    if (temp.length !== 8)
      return new Date();
  
    return new Date(parseInt(temp.substring(0, 4)), parseInt(temp.substring(4, 6))-1, parseInt(temp.substring(6, 8)), hour, minute, second, 0);
  }
  catch(e)
  {
    console.log(e.toString());
    return new Date();
  }
}

//date 형식을 YYYYMMDD형식의 정수값으로 변환
function GetDateToSerialDate(date)
{
  return parseInt(`${date.getFullYear()}${NumberFormatString(date.getMonth() + 1)}${NumberFormatString(date.getDate())}`);
}

//date 형식을 YYYYMMDDhhmm형식의 정수값으로 변환
function GetDateToSerialDate2(date)
{
  return parseInt(`${date.getFullYear()}${NumberFormatString(date.getMonth() + 1)}${NumberFormatString(date.getDate())}${NumberFormatString(date.getHours())}${NumberFormatString(date.getMinutes())}`);
}

//date 형식을 YYYYMM형식의 정수값으로 변환
function GetDateToSerialMonth(date)
{
  return parseInt(`${date.getFullYear()}${NumberFormatString(date.getMonth() + 1)}`);
}

//오늘일자를 YYYYMMDD형식으로 구함
function GetTodayDateSerial()
{
  return GetDateToSerialDate(new Date());
}

//오늘월자를 YYYYMM형식으로 구함
function GetTodayMonthSerial()
{
  return GetDateToSerialMonth(new Date());
}

//YYYYMMDD형식에서 년정보만 얻음
function GetSerialDateYear(v)
{
  let temp = v.toString();

  if (temp.length < 4)
    return "";

  return parseInt(temp.substring(0, 4));
}

//YYYYMMDD형식에서 년정보만 얻음
function GetSerialDateMonth(v)
{
  let temp = v.toString();

  if (temp.length < 6)
    return "";

  return parseInt(temp.substring(4, 6));
}

function GetBankNameByCode(bankCode)
{
  switch(bankCode)
  {
    case 1:
      return "국민은행";
    case 2:
        return "기업은행";
    case 3:
        return "농협은행";
    case 4:
        return "신한은행";
    case 5:
        return "산업은행";
    case 6:
        return "우리은행";
    case 7:
        return "한국씨티은행";
    case 8:
        return "하나은행";
    case 9:
        return "SC제일은행";
    case 10:
      return "경남은행";
    case 11:
      return "광주은행";
    case 12:
      return "대구은행";
    case 13:
      return "도이치은행";
    case 14:
      return "부산은행";
    case 15:
      return "저축은행";
    case 16:
      return "새마을금고";
    case 17:
      return "수협은행";
    case 18:
      return "신협중앙회";
    case 19:
      return "우체국";
    case 20:
      return "전북은행";
    case 21:
      return "제주은행";
    case 22:
      return "케이뱅크";
    case 23:
      return "카카오뱅크";
    case 24:
      return "토스뱅크";
    default:
      return "은행을 선택하세요.";
  }
}

const getShortedPathName = (v, len) =>
{
  if (!IsValidS(v))
    return "";

  if (v.length <= len)
    return v;

  return `${v.substring(0, len-3)}...`;
}

//문자에서 - 및 좌우 공백을 모두 제거함
const replaceLine = (str) =>
{
  return str.trim().replace(/-/gi, "");
}

const IsValidId = (id) => {
  try {
      const idCheck = new RegExp('^[^0-9][a-zA-Z0-9_-]*$');

      if (!idCheck.test(id)) {
          return false;
      }

      return true;
  }
  catch (e) {
      return false;
  }
}

const IsValidEmail = (email) => {
  if (!email)
      return false;

  let regex = new RegExp('[a-z0-9]+@[a-z]+\.[a-z]{2,3}');

  return regex.test(email);
};

const getAdsPlatformName = (platformCode) =>
{
  switch(platformCode)
  {
    case 0:
      return "인스타그램";
    case 1:
      return "페이스북";
    case 2:
      return "유튜브";
    case 3:
      return "틱톡";
    default:
      return "";
  }
}

const AdsTypeToText = (adsType) =>
{
  switch(adsType)
  {
    case 0: //인스타게시물좋아요
      return "좋아요 늘리기";
    case 1: //인스타프로필팔로우
      return "팔로우 늘리기";
    case 2: //인스타 댓글
      return "댓글 늘리기";
    case 3: //인스타 커스텀 댓글
      return "커스텀 댓글 늘리기";
    case 10: //페이스북 게시물 좋아요
      return "좋아요 늘리기";
    case 11: //페이스북 팔로우
      return "팔로우 늘리기";
    case 12: //페이스북 댓글
      return "댓글 늘리기";
    case 13: //페이스북 커스텀댓글
      return "커스텀 댓글 늘리기";
    case 14: //페이스북 커스텀댓글
      return "팔로우 늘리기";
    case 20: //유튜브 좋아요
      return "좋아요 늘리기";
    case 21: //유튜브 구독자
      return "구독자 늘리기";
    case 22: //유튜브 댓글
      return "댓글 늘리기";
    case 23: //유튜브 커스텀 댓글
      return "커스텀 댓글 늘리기";
    case 30: //틱톡 좋아요
      return "좋아요 늘리기";
    case 31: //틱톡 팔로우
      return "팔로우 늘리기";
    case 32: //틱톡 댓글
      return "댓글 늘리기";
    case 33: //틱톡 커스텀 댓글
      return "커스텀 댓글 늘리기";
    default:
      return "오류";
  }
}

const AdsTypeToText2 = (adsType) =>
{
  switch(adsType)
  {
    case -1:
      return "전체 SNS";
    case 0: //인스타게시물좋아요
      return "인스타그램 좋아요 늘리기";
    case 1: //인스타프로필팔로우
      return "인스타그램 팔로우 늘리기";
    case 2: //인스타 댓글
      return "인스타그램 댓글 늘리기";
    case 3: //인스타 커스텀 댓글
      return "인스타그램 커스텀 댓글 늘리기";
    case 10: //페이스북 게시물 좋아요
      return "페이스북 좋아요 늘리기";
    case 11: //페이스북 팔로우
      //return "페이스북 프로필 팔로우 늘리기";
      return "페이스북 팔로우 늘리기";
    case 12: //페이스북 댓글
      return "페이스북 댓글 늘리기";
    case 13: //페이스북 커스텀댓글
      return "페이스북 커스텀 댓글 늘리기";
    case 14:
      //return "페이스북 페이지 팔로우 늘리기";
      return "페이스북 팔로우 늘리기";
    case 20: //유튜브 좋아요
      return "유튜브 좋아요 늘리기";
    case 21: //유튜브 구독자
      return "유튜브 구독자 늘리기";
    case 22: //유튜브 댓글
      return "유튜브 댓글 늘리기";
    case 23: //유튜브 커스텀 댓글
      return "유튜브 커스텀 댓글 늘리기";
    case 30: //틱톡 좋아요
      return "틱톡 좋아요 늘리기";
    case 31: //틱톡 팔로우
      return "틱톡 팔로우 늘리기";
    case 32: //틱톡 댓글
      return "틱톡 댓글 늘리기";
    case 33: //틱톡 커스텀 댓글
      return "틱톡 커스텀 댓글 늘리기";
    default:
      return "오류";
  }
}

//해당 광고가 게시물에 대한 광고인가?
const isPostTypeAds = (adsType) =>
{
  switch(adsType)
  {
    case 0: //인스타게시물좋아요
    case 2: //인스타 댓글
    case 3: //인스타 커스텀 댓글
    case 10: //페이스북 게시물 좋아요
    case 12: //페이스북 댓글
    case 13: //페이스북 커스텀댓글
    case 20: //유튜브 좋아요
    case 22: //유튜브 댓글
    case 23: //유튜브 커스텀 댓글
    case 30: //틱톡 좋아요
    case 32: //틱톡 댓글
    case 33: //틱톡 커스텀 댓글
      return true;
    default:
      return false;
  }
}

const AdsGenderInfoToText = (gender) =>
{
  switch(gender)
  {
    case 0:
      return "무관";
    case 1:
      return "남성";
    case 2:
      return "여성";
    default:
      return "오류";
  }
}

const AdsAgeInfoToText = (age) =>
{
  switch(age)
  {
    case 0:
      return "무관";
    case 1:
      return "20대";
    case 2:
      return "30대";
    default:
      return "오류";
  }
}

const AdsRepeatInfoToText = (rc, con, dy) =>
{
  if (rc < 2)
    return "";

  return `${dy}분 ${con}개 ${rc}번`;
}

//포인트 충전 주문 결제 수단
const PurchaseModeToText = (pm) =>
{
  switch(pm)
  {
    case SNSPM_TRANS:
      return "계좌이체";
    case SNSPM_CARD:
    case SNSPM_CARD_PONE:
      return "카드";
    case SNSPM_VBANK:
    case SNSPM_VBANK_PONE:
      return "가상계좌";
    default:
      return "";
  }
}

//포인트충전 주문의 상태정보를 얻음
const GetPurchaseOrderStatus = (st) =>
{
  switch(st)
  {
    case 0: //주문이등록됨
      return "주문등록";
    case 1: //결제대기
      return "결제대기";
    case 2: //결제완료
      return "완료";
    case 3: //결제취소
      return "실패";
    case 4: //결제취소
      return "실패";
    default:
      return "";
  }
}

const GetSHA256 = (value) => {
  try {
      return CryptoJS.SHA256(value, defaultKey_hmac).toString();
  }
  catch (e) {
      if (LOG_ENABLE)
        console.log(`GetSHA256 failed : ${e.toString()}`);

      return null;
  }
}

//무통장입금 마감기한얻기
//orderDate : YYYYMMDD
const getVirtualBankLimitTime = (orderDateInfo) =>
{
  let orderDate = GetSerialDateToDate2(orderDateInfo, 23, 59, 59); //주문일자

  orderDate.setDate(orderDate.getDate() + 1);

  return `${orderDate.getFullYear()}-${NumberFormatString(orderDate.getMonth() + 1)}-${NumberFormatString(orderDate.getDate())}`;
}

//올바른 인스타그램 프로필주소인지 확인
const checkInstaProfileAddress = (link) =>
{
  if (checkInstaPostAddress(link))
    return false;

  return (IsValidS(link) && link.indexOf('https://www.instagram.com/') === 0);
}

//올바른 인스타그램 게시물 주소인지 확인
const checkInstaPostAddress = (link) =>
{
  return (IsValidS(link) && link.indexOf('https://www.instagram.com/p/') === 0);
}

//프로필 카드 주소인지 확인한다.
const checkInstaPfCardAddress = (link) =>
{
  return (IsValidS(link) && link.indexOf('https://www.instagram.com/') === 0 && link.indexOf('/profilecard') > 0);
}

//올바른 페이스북 프로필주소인지 확인
const checkFbProfileAddress = (link) =>
{
  if (checkFbPostAddress(link))
    return false;

  return (IsValidS(link) && link.indexOf('facebook.com/') >= 0);
}

//올바른 페이스북 게시물 주소인지 확인
const checkFbPostAddress = (link) =>
{
  if (!IsValidS(link))
    return false;

  if (!(IsValidS(link) && link.indexOf('facebook.com/') >= 0))
    return false;

  if (link.indexOf("/posts/") > 0)
    return true;
  
  if (link.indexOf("story_fbid") > 0)
    return true;
  
  return false;
}

//올바른 유튜브 프로필주소인지 확인
const checkYoutubeProfileAddress = (link) =>
{
  return (IsValidS(link) && link.indexOf('youtube.com/') >= 0);
}

//올바른 유튜브 게시물 주소인지 확인
const checkYoutubePostAddress = (link) =>
{
  return (IsValidS(link) && ((link.indexOf('youtube.com/') >= 0) || (link.indexOf('youtu.be/') >= 0)));
}

//올바른 틱톡 프로필주소인지 확인
const checkTiktokProfileAddress = (link) =>
{
  if (checkTiktokPostAddress(link))
    return false;
  
  return (IsValidS(link) && link.indexOf('https://www.tiktok.com/@') === 0);
}

//올바른 틱톡 게시물 주소인지 확인
const checkTiktokPostAddress = (link) =>
{
  if (!(IsValidS(link) && link.indexOf('https://www.tiktok.com/@') === 0))
    return false;

  if (link.indexOf("/video/") > 0)
    return true;

  return false;
}

//올바른 sns주소인지 확인하기
//snstype : 프로필(0), 게시물(1)
const checkSnsAddress = (platform, snsType, link) =>
{
  switch(platform)
  {
    case 0: //인스타
      if (snsType === 0)
        return checkInstaProfileAddress(link);
      return checkInstaPostAddress(link);
    case 1: //페북
      if (snsType === 0)
        return checkFbProfileAddress(link);
      return checkFbPostAddress(link);
    case 2: //유튜브
      if (snsType === 0)
        return checkYoutubeProfileAddress(link);
      return checkYoutubePostAddress(link);
    case 3: //틱톡
      if (snsType === 0)
        return checkTiktokProfileAddress(link);
      return checkTiktokPostAddress(link);
    default:
      return false;
  }
}

const getBingoProductType = (pdt) =>
{
  switch(pdt)
  {
    case 0: 
      return "식품";
    case 1: 
      return "생활";
    case 2: 
      return "전자제품";
    case 3: 
      return "뷰티";
    case 4: 
      return "의류";
    case 5: 
      return "여행·취미";
    case 6: 
      return "스포츠";
    case 7: 
      return "출산·육아";
    case 8: 
      return "인테리어";
    case 9: 
      return "도서";
    case 10: 
      return "패션잡화";
    case 11: 
      return "기타";
    case 12: 
      return "게임";
    case 13: 
      return "어플리케이션";
    case 14: 
      return "서비스";
    default:
      return "-";
  }
}

const getBingoButtonTypeName = (btn) =>
{
  switch(btn)
  {
    case 1:
      return "동영상 시청하기";
    case 2:
      return "구경하기";
    case 3:
      return "체험하기";
    case 4:
      return "혜택 확인하기";
    case 5:
      return "소개영상 보러가기";
    case 6:
      return "상품 상세보기";
    case 7:
      return "상품 구경하기";
    case 8:
      return "최저가 구매하기";
    case 9:
      return "할인 받고 구매하기";
    case 10:
      return "지금 주문하기";
    case 11:
      return "구매하기";
    case 12:
      return "지금 구매하기";
    case 13:
      return "지금 신청하기";
    case 14:
      return "문의하기";
    case 15:
      return "앱 다운 받기";
    case 16:
      return "다운로드";
    case 17:
      return "더 알아보기";
    case 18:
      return "카톡 추가하기";
    case 19:
      return "구독하기";
    case 20:
      return "쇼핑하기";
    case 21:
      return "혜택 받기";
    case 22:
      return "가입하기";
    default:
      return "-";
  }
}

const getBingoDesignName = (dt) =>
{
  switch(dt)
  {
    case 0:
      return "A형";
    case 1:
      return "B형";
    default:
      return "-";
  }
}

//빙고게임 타입이름 얻기 신규게임추가시코드추가
const getBingoGameTypeName = (gt) =>
{
  switch(gt)
  {
    case 1:
      return "쇼츠형 세줄 빙고";
    case 2:
    case 3:
    case 4:
    case 5:
      return "일반형 한줄 빙고";
    case 6:
      return "쇼츠형 한줄 빙고";      
    case 7:
    case 8:
      return "쇼츠형 한줄 빙고";
    default:
      return "-";
  }
}

//플레이횟수당 단가 얻기 신규게임추가시코드추가
const getBingoGameTypeCost = (gt) =>
{
  switch(gt)
  {
    case 1: //쇼츠형 3x3
      return 2.5;
    case 2: //일반형 100만, 3x1
      return 2.222222222;
    case 3: //일반형 45만, 3x1
      return 2.5;
    case 4: //일반형 9만, 3x1
      return 3;
    case 5: //일반형 1만, 3x1
      return 2.5;
    case 6: //집중형 3x1
      return 2.5;
    case 7: //쇼츠형 한줄빙고, 디럭스
      return 3.0;
    case 8: //쇼츠형 한줄빙고, 프리미엄
      return 2.5;
    default:
      return 2.5;
  }
}

const getBingoTargetInfo = (item) =>
{
  if (!item)
    return "-";

  let ageInfo = null;

  if ((item.agt & 0x1) !== 0 &&
    (item.agt & 0x2) !== 0 &&
    (item.agt & 0x4) !== 0 &&
    (item.agt & 0x8) !== 0 &&
    (item.agt & 0x10) !== 0)
    ageInfo = "전체";
  else
  {
    ageInfo = "";
    let newAge = true;

    for (let i = 0;i < 5; ++i)
    {
      let mask = 0;
      let age = null;

      switch(i)
      {
        case 0:
          mask = 0x1;
          age = "10대";
          break;
        case 1:
          mask = 0x2;
          age = "20대";
          break;
        case 2:
          mask = 0x4;
          age = "30대";
          break;
        case 3:
          mask = 0x8;
          age = "40대";
          break;
        case 4:
          mask = 0x10;
          age = "50대";
          break;
        default:
          break;
      }

      if ((item.agt & mask) !== 0)
      {
        if (!newAge)
          ageInfo += ` ${age}`;
        else
        {
          ageInfo += age;
          newAge = false;
        }
      }
    }
  }
  
  let genderInfo = null;

  switch(item.gtt)
  {
    case 0:
      genderInfo = "전체";
      break;
    case 1:
      genderInfo = "남성";
      break;
    case 2:
      genderInfo = "여성";
      break;
    default:
      break;
  }

  return `연령 : ${ageInfo} / 성별 : ${genderInfo}`;
}

const getBingoAgeInfo = (item) =>
{
  if (!item)
    return "-";

  let ageInfo = null;

  if ((item.agt & 0x1) !== 0 &&
    (item.agt & 0x2) !== 0 &&
    (item.agt & 0x4) !== 0 &&
    (item.agt & 0x8) !== 0 &&
    (item.agt & 0x10) !== 0)
    ageInfo = "전체";
  else
  {
    ageInfo = "";
    let newAge = true;

    for (let i = 0;i < 5; ++i)
    {
      let mask = 0;
      let age = null;

      switch(i)
      {
        case 0:
          mask = 0x1;
          age = "10대";
          break;
        case 1:
          mask = 0x2;
          age = "20대";
          break;
        case 2:
          mask = 0x4;
          age = "30대";
          break;
        case 3:
          mask = 0x8;
          age = "40대";
          break;
        case 4:
          mask = 0x10;
          age = "50대";
          break;
        default:
          break;
      }

      if ((item.agt & mask) !== 0)
      {
        if (!newAge)
          ageInfo += ` ${age}`;
        else
        {
          ageInfo += age;
          newAge = false;
        }
      }
    }
  }
  
  return ageInfo;
}

const getBingoGenderInfo = (gt) =>
{
  switch(gt)
  {
    case 0:
      return "전체";
    case 1:
      return "남성";
    case 2:
      return "여성";
    default:
      return "";
  }
}

//빙고 시작 희망 일시 정보를 얻음
const getBingoRequestDate = (rdt) =>
{
  const temp = rdt.toString();

  if (temp.length !== 12)
    return rdt.toString();

  return `${temp.substring(0, 4)}-${temp.substring(4, 6)}-${temp.substring(6, 8)} ${temp.substring(8, 10)}시`;
}

//yyyymmddhhmm 형식을 yyyy년 mm월 dd일 hh:mm 시까지로 변환
const convertBingoDateInfo = (rdt) =>
{
  if (!IsValidV(rdt))
    return '';

  const temp = rdt.toString();

  if (temp.length !== 12)
    return rdt.toString();

  return `${temp.substring(0, 4)}년 ${temp.substring(4, 6)}월 ${temp.substring(6, 8)}일 ${temp.substring(8, 10)}:${temp.substring(10, 12)} 까지`;
}

//yyyymmddhhmm 형식을 yyyy-mm-dd hh:mm 으로 변환
const convertBingoDateInfo2 = (rdt) =>
{
  if (!IsValidV(rdt))
    return '';

  const temp = rdt.toString();

  if (temp.length !== 12)
    return rdt.toString();

  return `${temp.substring(0, 4)}-${temp.substring(4, 6)}-${temp.substring(6, 8)} ${temp.substring(8, 10)}:${temp.substring(10, 12)}`;
}

const getBingoPurchaseMode = (pm) =>
{
  switch(pm)
  {
    case BPM_CARD:
    case BPM_CARD_PONE:
      return "카드";
    case BPM_VBANK:
    case BPM_VBANK_PONE:
      return "가상계좌";
    case BPM_TRANS:
      return "계좌이체";
    default:
      return "오류";
  }
}

//빙고 광고의 기본 판매 가격 정보를 얻음 신규게임추가시코드추가
const getBingoDefaultPrice = (gt) =>
{
  switch(gt)
  {
    case 1: //3x3 쇼츠형 프리미엄
      return 1000000;
    case 2: //3x1 일반 프리미엄
      return 1000000;
    case 3: //3x1 일반 디럭스
      return 450000;
    case 4: //3x1 일반 베이직
      return 90000;
    case 5: //3x1 일반 스타터
      if (APP_MODE !== 0)
        return 10000;
      return 100;
    case 6: //3x1, 미사용
      return 1000000;
    case 7: //3x1 쇼츠형 디럭스
      return 450000;
    case 8: //3x1 쇼츠형 프리미엄
      return 1000000;
    default:
      return 0;
  }
}

//총빙고팡팡 판매 가격, 구매개수와 기본가격을 적용하고 부가세를적용한 금액을 계산한다.
const getBingoTotalPrice = (item) =>
{
  let price = getBingoDefaultPrice(item.gt) * item.bc;
  
  price += Math.floor(price * 0.1);

  return price;
}

const getBingoOrderStatus = (st, st2) =>
{
  switch(st)
  {
    case 0:
      return "임시등록";
    case 1:
      return "등록대기";
    case 2:
      return "광고 검수중";
    case 3:
      return "광고 검수중";
    case 4:
      return "승인대기";
    case 5:
      return "게시대기";
    case 6:
      if ((st2 & 0x4) !== 0)
        return "일시중지";
      return "광고 게시중";
    case 7:
      return "광고 종료";
    case 8:
      return "광고 취소";
    default:
      return "-";
  }
}

//올바른 빙고게임 코드인지 확인
const isValidBingoGame = (gc) =>
{
  switch(gc)
  {
    case 1:
    case 2:
    case 3:
    case 4:
    case 5:
    case 6:
    case 7:
    case 8:
      return true;
    default:
      return false;
  }
}

//해당 빙고 게임 플레이 횟수 정보를 얻음 신규게임추가시코드추가
const getBingoTotalGameCount = (gc) =>
{
  switch(gc)
  {
    case 1:
      return 400000;
    case 2:
      return 450000;
    case 3:
      return 180000;
    case 4:
      return 30000;
    case 5:
      return 4000;
    case 6:
      return 450000;
    case 7:
      return 150000;
    case 8:
      return 400000;
    default:
      return 0;
  }
}

//예상영상 시청횟수 얻기 신규게임추가시코드추가
const getBingoTotalVideoCount = (gc) =>
{
  switch(gc)
  {
    case 1:
    case 6:
      return 32000;
    case 7:
      return 12000;
    case 8:
      return 32000;
    default:
      return 0;
  }
}

//3x1형빙고인가? 신규게임추가시코드추가
const isBingo3to1 = (gc) =>
{
  switch(gc)
  {
    case 1:
      return false;
    default:
      return true;
  }
}

//비디오 시청가능한 빙고인가? 신규게임추가시코드추가
const checkVideoBingo = (gameType) =>
{
  switch(gameType)
  {
    case 1:
    case 6:
    case 7:
    case 8:
      return true;
    default:
      return false;
  }
}

const getFilExtension = (filename) => {
  if (!IsValidS(filename))
    return "";

  const index = filename.lastIndexOf('.');

  if (index >= 0)
    return filename.substring(index, filename.length);

  return "";
}  

const isValidWebLink = (url) =>
{
  if (!IsValidS(url))
    return false;

  const temp = url.toLowerCase();

  return temp.startsWith("http://") || temp.startsWith("https://");
}

const getBingoBankInfo = (item) =>
{
  if (!IsValidV(item.bankInfo) || item.pm === BPM_CARD || item.pm === BPM_CARD_PONE)
    return "";

  if (item.pm === BPM_VBANK || item.pm === BPM_VBANK_PONE)
  {
    return `${item.bankInfo.bn}, ${item.bankInfo.ba}, ${item.bankInfo.bun}, ${convertBingoDateInfo(item.bankInfo.ldt)}`;
  }

  return `${item.bankInfo.bn}, ${item.bankInfo.ba}, ${item.bankInfo.bun}, 입금자명: ${item.wun}, ${convertBingoDateInfo(item.bankInfo.ldt)}`;
}

const adjustColumnWidth = (worksheet) => {
  worksheet.columns.forEach(column => {
    const lengths = column.values.map(v => v.toString().length);
    const maxLength = Math.max(Math.max(...lengths.filter(v => typeof v === 'number')) + 20, column.header.length + 6);
    column.width = maxLength;
  });
}

const getValidFileName = (fileName, defaultName, oriName) =>
{
  if (!IsValidS(fileName))
  {
    if (IsValidS(oriName))
      return oriName;

    return defaultName;
  }

  return fileName;
}

const getValidString = (v, v2) =>
{
  if (!IsValidS(v))
    return v2;

  return v;
}

const getValidString2 = (v, v2) =>
{
  if (!IsValidS(v) || v === "n")
    return v2;

  return v;
}

export {
  BPM_CARD,
  BPM_VBANK,
  BPM_TRANS,
  BPM_CARD_PONE,
  BPM_VBANK_PONE,
  SNSPM_TRANS,
  SNSPM_CARD,
  SNSPM_VBANK,
  SNSPM_CARD_PONE,
  SNSPM_VBANK_PONE,
  NumberFormatString,
  DownloadImageBlob,
  ConvertImageBlobToFile,
  ResizeImage,
  DownloadFile,
  UploadFileWithExt,
  UploadFile,
  UploadFileUrl,
  SaveMultiFileToZip,
  ToBase64, FromBase64, IsValidS, IsValidV, 
  GetFormattedCNum, GetFormattedPhone, GetFormattedCNum2, GetFormattedPhone2, GetChatTime,GetDataTime,GetDataDate, GetDataDateAndTime, isImageFile,
  ConvertDateInfo,ConvertSerialMonthInfo,
  GetNextDateFormat2,
  GetFormattedNum, GetValueOnly, GetDateYear, GetDateMonth, GetDateDay,
  GetCurrentYear, GetCurrentMonth, GetCurrentDay, GetPrevMonth, GetNextMonth, GetLastDate, GetMonthRage, GetBankNameByCode,
  GetSerialDateToDate, GetSerialDateToDate2, 
  GetDateToSerialDate,
  GetDateToSerialDate2,
  GetTodayDateSerial, GetDateToSerialMonth, GetTodayMonthSerial,
  GetSerialDateYear, GetSerialDateMonth, getShortedPathName, replaceLine, IsValidId, IsValidEmail,
  getAdsPlatformName,
  AdsTypeToText, AdsTypeToText2,
  isPostTypeAds,
  AdsGenderInfoToText, AdsAgeInfoToText, AdsRepeatInfoToText, PurchaseModeToText, GetPurchaseOrderStatus,
  GetSHA256,
  getVirtualBankLimitTime,
  checkSnsAddress,
  checkInstaPfCardAddress,
  getBingoProductType,
  getBingoButtonTypeName,
  getBingoDesignName,
  getBingoGameTypeName,
  getBingoGameTypeCost,
  getBingoTargetInfo,
  getBingoAgeInfo,
  getBingoGenderInfo,
  getBingoRequestDate,
  convertBingoDateInfo,
  convertBingoDateInfo2,
  getBingoPurchaseMode,
  getBingoDefaultPrice,
  getBingoTotalPrice,
  getBingoOrderStatus,
  isValidBingoGame,
  getBingoTotalGameCount,
  getBingoTotalVideoCount,
  isBingo3to1,
  checkVideoBingo,
  getFilExtension,
  isValidWebLink,
  getBingoBankInfo,
  adjustColumnWidth,
  getValidFileName,
  getValidString,
  getValidString2,
};