import React from 'react';
import './Order.css';
import '../../App.css';
import { APP_MODE, GetTempImgUrl, INSTAGRAM_CHECK_MODE, LOG_ENABLE, gotoFirstPage, gotoTalkPage, handleAppNavi, mobileOn, withNavigation } from '../../URL/AppUrl';
import {  ConvertImageBlobToFile, DownloadImageBlob, IsValidS, IsValidV, ResizeImage, UploadFile, UploadFileUrl, UploadFileWithExt, checkInstaPfCardAddress, checkSnsAddress, } from '../../Util/Util';
import Loading from '../Loading/Loading';
import TopBar from '../Top/TopBar';
import FooterBar from '../Footer/Footer';
import { LoginStoreInstance } from '../../Stores/LoginStore';
import Term from '../Term/Term';
import Privacy from '../Term/Privacy';
import Talk from '../Talk/Talk';
import OrderLeft from './OrderLeft';
import OrderRight from './OrderRight';
import MobileMenu from '../Top/MobileMenu';
import OrderRightMb from './OrderRightMb';
import { SNSQueryInstance } from '../../Stores/SnsQuery';
import { AdsStoreInstance } from '../../Stores/AdsStore';
import { checkAdsSystem, getAdsSystemMaintMsg, getAdsTypeCode } from './OrderDescUtil';
import OrderDescPop from './OrderDescPop';
import { resetAllUserData } from '../../Stores/StoreManager';
import Login from '../Login/Login';
import FindId from '../Login/FindId';
import FindPw from '../Login/FindPw';
import NaviBar from '../Navi/Navi';
import { observer } from 'mobx-react';

//sns플라이 광고 주문하기
class Order extends React.Component
{
  state = {
    loading: false,
    showTerm: false,
    showPrivacy: false,
    showMobileMenu: false,
    showLogin: false,
    showFindId: false,
    showFindPw: false,

    platform: 0,//인스타(0), 페이스북(1), 유튜브(2), 틱톡(3)
    adsType: 1, //좋아요(0), 팔로우(1), 댓글(2), 페이지팔로우(3, 페이스북만)
    ageType: 0, //전체(0), 20대(1), 30대(1)
    genderType: 0, //전체(0), 남성(1), 여성(2)
    link: '', //sns주소
    count: 0, //구매수량
    repeat: 0, //반복없음(0), 반복(1)
    repeatCount: 0, //반복횟수
    repeatDelay: 0, //반복 딜레이, 분
    snsChecked: false, //입력한 SNS주소에 대해서 확인을 완료했는가?
    
    basePrice: 0, //기본가격
    genderPrice: 0,
    agePrice: 0,
    userPoint: 0,
    partnerPoint: 0,

    resultPrice: 0, //최종 결과값

    adsConfig: 0, //광고시스템 중지 플래그

    showGuidePop: false, //주문가이드 팝업보이기

    //사이징처리
    naviWidth: 0,
    contWidth: 0,

    height: 0,
  };

  defaultAdsType = 1; //플랫폼변경시 기본 광고타입
  intervalId = -1;
  snsInfo = null; //서버에 조회된 sns주소의 정보
  thumnailImageName1 = null; //서버에 업로드할 썸네일 이미지 원본주소
  thumnailImageName2 = null; //우리 서버에 업로드된 썸네일 이미지 이름
  imageFile = null;

  update = () =>
  {
    if (LoginStoreInstance.sessionError && LoginStoreInstance.loggedIn)
    {
      LoginStoreInstance.sessionError = false;
      alert(`인증 세션이 만료되었습니다.`);
      this.processLogOut();
    }
  }

  //현재 선택된 광고 타입에 따라서 가격 정보를 갱신함
  calcPrice = (platform, adt, genderType, ageType, count, repeatCount) =>
  {
    if (!LoginStoreInstance.loggedIn)
      return;

    const adsType = getAdsTypeCode(platform, adt);

    if (adsType < 0)
    {
      if (LOG_ENABLE)
        console.log(`invalid ads type`);
      return;
    }

    if (LOG_ENABLE)
      console.log(`ads type : ${adsType}, ${platform}-${adt}`);

    const priceInfo = AdsStoreInstance.findPriceInfo(adsType);

    if (!priceInfo)
    {
      this.setPriceInfo(0, 0, 0, 0, 0, 0);
      return;
    }

    const baseCost = priceInfo.up + priceInfo.pp + priceInfo.bp;
    const userPoint = priceInfo.up;
    const partnerPoint = priceInfo.pp;
    const genderCost = genderType > 0 ? priceInfo.gp : 0;
    const ageCost = ageType > 0 ? priceInfo.ap : 0;
    let resultCost = baseCost;

    if (genderType > 0)
      resultCost += genderCost;

    if (ageType > 0)
      resultCost += ageCost;

    if (count > 0)
    {
      resultCost *= count;

      if (repeatCount > 0)
        resultCost *= repeatCount;
    }
    else
    {
      resultCost = 0;
    }

    if (LOG_ENABLE)
      console.log(`cost info : base: ${baseCost}, gender: ${genderCost}, age : ${ageCost}, result: ${resultCost}`);

    this.setPriceInfo(baseCost, genderCost, ageCost, resultCost, userPoint, partnerPoint);
  }

  getBaseCost = (platform, adt) =>
  {
    const adsType = getAdsTypeCode(platform, adt);
    const priceInfo = AdsStoreInstance.findPriceInfo(adsType);

    if (priceInfo)
      return (priceInfo.up + priceInfo.pp + priceInfo.bp);

    return 0;
  }

  setLoading = (v) =>
  {
    this.setState((prevState) => ({...prevState, loading: v}));
  }

  showMobileMenu = (v) =>
  {
    this.setState((prevState) => ({...prevState, showMobileMenu: v}));
  }

  setPlatform = (v) =>
  {
    this.setState((prevState) => ({...prevState, platform: v}));
  }

  setAdsType = (v) =>
  {
    this.setState((prevState) => ({...prevState, adsType: v}));
  }

  setAgeType = (v) =>
  {
    this.setState((prevState) => ({...prevState, ageType: v}));
  }

  setGenderType = (v) =>
  {
    this.setState((prevState) => ({...prevState, genderType: v}));
  }

  setLink = (v) =>
  {
    this.setState((prevState) => ({...prevState, link: v}));
  }

  setCount = (v) =>
  {
    this.setState((prevState) => ({...prevState, count: v}));
  }

  setRepeat = (v) =>
  {
    this.setState((prevState) => ({...prevState, repeat: v}));
  }

  setRepeatCount = (v) =>
  {
    this.setState((prevState) => ({...prevState, repeatCount: v}));
  }

  setRepeatDelay = (v) =>
  {
    this.setState((prevState) => ({...prevState, repeatDelay: v}));
  }

  showTerm = (v) =>
  {
    this.setState((prevState) => ({...prevState, showTerm: v}));
  }

  showPrivacy = (v) =>
  {
    this.setState((prevState) => ({...prevState, showPrivacy: v}));
  }

  setSnsChecked = (v) =>
  {
    this.setState((prevState) => ({...prevState, snsChecked: v}));
  }

  setPriceInfo = (baseCost, genderCost, ageCost, resultCost, userPoint, partnerPoint) =>
  {
    this.setState((prevState) => ({...prevState, basePrice: baseCost, genderPrice: genderCost, agePrice: ageCost, resultPrice: resultCost,
      userPoint: userPoint, partnerPoint: partnerPoint
    }));
  }

  setAdsConfig = (v) =>
  {
    this.setState((prevState) => ({...prevState, adsConfig: v}));
  }

  setShowGuidePop = (v) =>
  {
    this.setState((prevState) => ({...prevState, showGuidePop: v}));
  }

  //-----------------------------------------------------------------------------------------------------------------------------
  showLogin = (v) =>
  {
    this.setState((prevState) => ({...prevState, showLogin: v}));
  }

  showFindId = (v) =>
  {
    this.setState((prevState) => ({...prevState, showFindId: v}));
  }

  showFindPw = (v) =>
  {
    this.setState((prevState) => ({...prevState, showFindPw: v}));
  }

  handleLoginEvent = (eventCode) =>
  {
    if (this.state.loading)
      return;

    switch(eventCode)
    {
      case 0: //닫기
        this.showLogin(false);
        return;
      case 1: //회원가입
        this.handleRegister();
        return;
      case 2: //id찾기
        this.showLogin(false);
        this.showFindId(true);
        return;
      case 3: //비번찾기
        this.showLogin(false);
        this.showFindPw(true);
        return;
      case 100: //로그인 성공
        this.showLogin(false);
        this.loadCost();
        AdsStoreInstance.loadAdsConfig(this.onConfigLoaded);
        return;
    }
  }

  handleRegister = () =>
  {
    if (this.state.loading)
      return;

    this.showLogin(false);
    this.showFindId(false);
    this.showFindPw(false);
    this.handleNavi(5);
  }

  handleFindPw = () =>
  {
    if (this.state.loading)
      return;

    this.showLogin(false);
    this.showFindId(false);
    this.showFindPw(true);
  }

  handleFindIdLogin = () =>
  {
    if (this.state.loading)
      return;

    this.showLogin(true);
    this.showFindId(false);
    this.showFindPw(false);
  }
  //-----------------------------------------------------------------------------------------------------------------------------
  
  handleNavi = (naviIndex) =>
  {
    if (this.state.loading)
      return;

    if (LOG_ENABLE)
      console.log(`handleNavi : ${naviIndex}`);

    //console.log(`${this.props.navigate}`);
    this.showMobileMenu(false);
    handleAppNavi(this.props.navigate, naviIndex);
  }

  handleSnsOrder = (orderType) =>
  {
    if (this.state.loading)
      return;

    if (LOG_ENABLE)
      console.log(`handleSnsOrder : ${orderType}`);

    this.setPlatform(orderType);
    this.setAdsType(this.defaultAdsType);
    this.setSnsChecked(false);
    this.calcPrice(orderType, this.defaultAdsType, this.state.genderType, this.state.ageType, this.state.count, this.state.repeatCount);
  }

  handleMenu = (menuIndex) =>
  {
    if (this.state.loading)
      return;

    switch(menuIndex)
    {
      case 0: //로그인
        this.showLogin(true);
        return;
      case 6: //이용약관
        this.showTerm(true);
        return;
      case 7: //개인정보 처리방침
        this.showPrivacy(true);
        return;
      case 100: //로그아웃
        if (!window.confirm('로그아웃 할까요?'))
          return;

        this.processLogOut();
        return;
    }
  }

  processLogOut = () =>
  {
    resetAllUserData();
    gotoFirstPage();
  }

  //사이징----------------------------------------------------------------------------------------------
  setSize = (navi, cont, height) =>
  {
    this.setState((prevState) => ({...prevState, naviWidth : navi, contWidth : cont, height : height}));
  }

  resetContsize = () =>
  {
    //네비게이션 영역은 전체의 16%정도
    let naviWidth = 0.16 * window.innerWidth;

    if (naviWidth < 300) //최소크기300
      naviWidth = 300;

    //콘텐츠영역크기 계산
    let contWidth = window.innerWidth - naviWidth;

    if (contWidth < 1220)
      contWidth = 1220;

    this.setSize(naviWidth, contWidth, window.innerHeight - 70)
  }

  onResize = () => {
    this.resetContsize();
  }
  //사이징----------------------------------------------------------------------------------------------
    
  componentDidMount() {
    this.resetContsize();

    window.addEventListener("resize", this.onResize);

    this.intervalId = setInterval(this.update, 1000);

    this.setPlatform(AdsStoreInstance.orderType);
    this.setAdsType(this.defaultAdsType);

    //로그인되어 있지 않은경우, 혹시 새로고침했을수도 있다.
    if (!LoginStoreInstance.loggedIn)
    {
      if (LoginStoreInstance.loadToken()) //인증토큰 정보가 존재한다면
      {
        this.setLoading(true);
        LoginStoreInstance.loadProfile(this.onProfileLoadedForRefresh);
      }
    }
    else
    {
      if (!LoginStoreInstance.isProfileReady) //프로필이 준비되지 않았다면
      {
        this.setLoading(true);
        LoginStoreInstance.loadProfile(this.onProfileLoaded);
      }
      else
      {
        //마지막 포인트 조회 시점부터 현재까지 경과 시간이 1분이상 경과한경우
        if (LoginStoreInstance.getPassedPointTime() > 60)
        {
          LoginStoreInstance.catchPointRefreshTime();
          LoginStoreInstance.refreshUserPoint(null);
        }

        this.loadCost();

        AdsStoreInstance.loadAdsConfig(this.onConfigLoaded);
      }
    }
  }

  componentWillUnmount = () =>
  {
    window.removeEventListener("resize", this.onResize);

    if (this.intervalId !== -1)
    {
      clearInterval(this.intervalId);
      this.intervalId = -1;
    }
  }

  onProfileLoadedForRefresh = (resultCode) =>
  {
    this.setLoading(false);

    if (resultCode !== 0)
    {
      if (LOG_ENABLE)
        console.log(`invalid profile`);

      LoginStoreInstance.removeToken();

      return;
    }

    this.loadCost();

    AdsStoreInstance.loadAdsConfig(this.onConfigLoaded);
  }

  onProfileLoaded = (resultCode) =>
  {
    this.setLoading(false);

    if (resultCode !== 0)
    {
      alert(`프로필 읽기 중 오류가 발생했습니다.\n다시 로그인해 주세요.`);
      this.processLogOut();
      return;
    }

    this.loadCost();

    AdsStoreInstance.loadAdsConfig(this.onConfigLoaded);
  }

  onConfigLoaded = (resultCode, cfg) =>
  {
    if (resultCode !== 0)
    {
      alert(`광고시스템 설정 정보를 가져오지 못했습니다.\n오류코드 ${resultCode}`);
      return;
    }

    if (LOG_ENABLE)
      console.log(`ads config loaded : ${cfg}`);

    this.setAdsConfig(cfg);
  }

  //광고 단가 정보를 수신한다.
  loadCost = () =>
  {
    this.setLoading(true);
    AdsStoreInstance.loadPrice(this.onLoadPrice);
  }

  onLoadPrice = (resultCode) =>
  {
    this.setLoading(false);

    if (resultCode !== 0 || !AdsStoreInstance.isPriceValid())
    {
      alert(`단가 정보 수신 중 오류가 발생했습니다.\n잠시 후 이용해 주세요.`);
      return;
    }

    this.calcPrice(this.state.platform, this.state.adsType, this.state.genderType, this.state.ageType, this.state.count,
      this.state.repeatCount);
  }

  handleAdsSelection = (index, value) =>
  {
    switch(index)
    {
      case 0://플랫폼
        this.setPlatform(value);
        this.setAdsType(this.defaultAdsType);
        this.setSnsChecked(false);
        this.calcPrice(value, this.defaultAdsType, this.state.genderType, this.state.ageType, this.state.count, this.state.repeatCount);
        return;
      case 1: //광고타입
        this.setAdsType(value);
        this.setSnsChecked(false);
        this.calcPrice(this.state.platform, value, this.state.genderType, this.state.ageType, this.state.count, this.state.repeatCount);
        return;
      case 2: //나이타입
        this.setAgeType(value);
        this.calcPrice(this.state.platform, this.state.adsType, this.state.genderType, value, this.state.count, this.state.repeatCount);
        return;
      case 3: //성별타입
        this.setGenderType(value);
        this.calcPrice(this.state.platform, this.state.adsType, value, this.state.ageType, this.state.count, this.state.repeatCount);
        return;
      case 4: //링크주소
        this.setLink(value);
        this.setSnsChecked(false);
        return;
      case 5: //구매개수
        let count = 0;
        if (!IsValidS(value) || isNaN(value))
          count = 0;
        else
          count = parseInt(value);

        this.setCount(count);

        this.calcPrice(this.state.platform, this.state.adsType, this.state.genderType, this.state.ageType, count, this.state.repeatCount);
        return;
      case 6: //반복여부
        this.setRepeat(value);
        this.setCount(0);
        this.setRepeatCount(0);
        this.setRepeatDelay(0);
        this.calcPrice(this.state.platform, this.state.adsType, this.state.genderType, this.state.ageType, 0, 0);
        return;
      case 7: //반복횟수
        this.setRepeatCount(value);
        this.calcPrice(this.state.platform, this.state.adsType, this.state.genderType, this.state.ageType, this.state.count, value);
        return;
      case 8: //반복딜레이
        this.setRepeatDelay(value);
        return;
      case 9: //반복구매개수
        this.setCount(value);
        this.calcPrice(this.state.platform, this.state.adsType, this.state.genderType, this.state.ageType, value, this.state.repeatCount);
        return;
      default:
        return;
    }
  }

  //프로필 또는 페이지에 관한 광고인가?
  isProfileAds = () =>
  {
    switch(this.state.adsType)
    {
      case 0: //게시물 좋아요
      case 2: //게시물 댓글
        return false;
      case 1: //프로필 팔로우
      case 3: //페이지 팔로우
        return true;
      default:
        return false;
    }
  }

  getYoutubeThumbnail = () =>
  {
    if (!this.snsInfo || !IsValidV(this.snsInfo.thumbnails))
      return null;

    if (IsValidV(this.snsInfo.thumbnails.high) && IsValidS(this.snsInfo.thumbnails.high.url))
    {
      return this.snsInfo.thumbnails.high.url;
    }

    if (IsValidV(this.snsInfo.thumbnails.medium) && IsValidS(this.snsInfo.thumbnails.medium.url))
    {
      return this.snsInfo.thumbnails.medium.url;
    }

    if (IsValidV(this.snsInfo.thumbnails.default) && IsValidS(this.snsInfo.thumbnails.default.url))
    {
      return this.snsInfo.thumbnails.default.url;
    }

    return null;
  }
  
  //주문하기 버튼 눌린경우
  handleOrder = async () =>
  {
    if (this.state.loading)
      return;

    if (!LoginStoreInstance.loggedIn)
    {
      alert(`SNS 주문을 위해 로그인이 필요합니다.`);
      this.handleMenu(0);
      return;
    }

    this.calcPrice(this.state.platform, this.state.adsType, this.state.genderType, this.state.ageType, this.state.count,
      this.state.repeatCount);

    if (!checkAdsSystem(this.state.adsConfig, this.state.platform, this.state.adsType))
    {
      alert(getAdsSystemMaintMsg(this.state.platform, this.state.adsType));
      return;
    }

    if (!AdsStoreInstance.isPriceValid())
    {
      alert('상품 단가 수신 중 오류가 발생했습니다.\n페이지를 새로 고침해 주세요.');
      return;
    }

    if (!IsValidS(this.state.link))
    {
      alert('SNS 주소를 입력해 주세요.');
      return;
    }

    if (!this.state.count || this.state.count < 1)
    {
      alert('구매 개수를 입력해 주세요.');
      return;
    }

    if (this.state.count < 5 || this.state.count > 30000)
    {
      alert('구매 개수가 올바르지 않습니다.\n구매 개수는 5 ~ 30,000 까지입니다.');
      return;
    }

    //반복설정된경우
    if (this.state.repeat === 1)
    {
      if (!this.state.repeatDelay)
      {
        alert('반복 딜레이 시간을 입력해 주세요.');
        return;
      }

      if (!this.state.repeatCount)
      {
        alert('반복 횟수를 입력해 주세요.');
        return;
      }
    }

    if (!this.state.snsChecked)
    {
      alert('SNS주소 입력창의 확인 버튼을 눌러 SNS주소를 확인해 주세요.');
      return;
    }

    const adsTypeCode = getAdsTypeCode(this.state.platform, this.state.adsType);

    if (adsTypeCode < 0)
    {
      alert('광고 종류를 올바르게 선택해 주세요.');
      return;
    }

    if (this.state.resultPrice < 1)
    {
      alert(`플랫폼/광고타입/구매개수가 올바른지 확인해 주세요.`);
      return;
    }
    
    //총금액을 계산하고 이용자 보유포인트에서 확인한다.
    if (this.state.resultPrice > LoginStoreInstance.getUserPoint)
    {
      alert(`보유 포인트가 부족합니다.`);
      return;
    }

    //금액이 맞다면, 해당 sns의 썸네일 이미지를 우리측 서버에 업로드 하도록한다.
    if (this.state.platform === 0) //인스타
    {
      //썸네일 이미지를 추출해서 서버에 업로드해야 한다.
      if (this.isProfileAds()) //인스타 프로필의 경우 썸네일 이미지는 결과의 picture에
      {
        this.thumnailImageName1 = this.snsInfo ? this.snsInfo.picture : null;
        this.thumnailImageName2 = "n";

        if (IsValidS(this.thumnailImageName1)) //프로필 이미지가 있으면 다운로드 해서 업로드 절차를 진해한다.
        {
          await this.doUploadThumnail();
        }
        else //없으면 기본 이미지가 사용되도록 하고 바로 광고 주문을 시도한다.
        {
          this.thumnailImageName2 = "n";
          this.doRequestAds();
        }
      }
      else //게시물일경우에는 images배열에 있다.
      {
        if (this.snsInfo && IsValidS(this.snsInfo.images))
        {
          this.thumnailImageName1 = this.snsInfo.images[0];
          this.thumnailImageName2 = "n";
          await this.doUploadThumnail();
        }
        else
        {
          this.thumnailImageName2 = "n";
          this.doRequestAds();
        }
      }
      return;
    }
    else if (this.state.platform === 1) //페이스북
    {
      //썸네일 이미지를 추출해서 서버에 업로드해야 한다.
      if (this.isProfileAds()) //페이스북 프로필
      {
        this.thumnailImageName1 = this.snsInfo ? this.snsInfo.picture : null;
        this.thumnailImageName2 = "n";

        if (IsValidS(this.thumnailImageName1)) //프로필 이미지가 있으면 다운로드 해서 업로드 절차를 진해한다.
        {
          await this.doUploadThumnail();
        }
        else //없으면 기본 이미지가 사용되도록 하고 바로 광고 주문을 시도한다.
        {
          this.thumnailImageName2 = "n";
          this.doRequestAds();
        }
      }
      else //게시물일경우에는 images배열에 있다.
      {
        if (this.snsInfo && IsValidS(this.snsInfo.images))
        {
          this.thumnailImageName1 = this.snsInfo.images[0];
          this.thumnailImageName2 = "n";
          await this.doUploadThumnail();
        }
        else
        {
          this.thumnailImageName2 = "n";
          this.doRequestAds();
        }
      }
      return;
    }
    else if (this.state.platform === 2) //유튜브
    {
      //썸네일 이미지를 추출해서 서버에 업로드해야 한다.
      if (this.isProfileAds()) //인스타 프로필의 경우 썸네일 이미지는 결과의 picture에
      {
        this.thumnailImageName1 = this.snsInfo ? this.snsInfo.picture : null;
        this.thumnailImageName2 = "n";

        if (IsValidS(this.thumnailImageName1)) //프로필 이미지가 있으면 다운로드 해서 업로드 절차를 진해한다.
        {
          await this.doUploadThumnail();
        }
        else //없으면 기본 이미지가 사용되도록 하고 바로 광고 주문을 시도한다.
        {
          this.thumnailImageName2 = "n";
          this.doRequestAds();
        }
      }
      else //게시물일경우에는 thumbnails
      {
        const thumbnail = this.getYoutubeThumbnail();

        if (IsValidS(thumbnail))
        {
          this.thumnailImageName1 = thumbnail;
          this.thumnailImageName2 = "n";
          await this.doUploadThumnail();
        }
        else
        {
          this.thumnailImageName2 = "n";
          this.doRequestAds();
        }
      }
      return;
    }
    else if (this.state.platform === 3) //틱톡
    {
      //썸네일 이미지를 추출해서 서버에 업로드해야 한다.
      if (this.isProfileAds()) //인스타 프로필의 경우 썸네일 이미지는 결과의 picture에
      {
        this.thumnailImageName1 = this.snsInfo ? this.snsInfo.picture : null;
        this.thumnailImageName2 = "n";

        if (IsValidS(this.thumnailImageName1)) //프로필 이미지가 있으면 다운로드 해서 업로드 절차를 진해한다.
        {
          await this.doUploadThumnail();
        }
        else //없으면 기본 이미지가 사용되도록 하고 바로 광고 주문을 시도한다.
        {
          this.thumnailImageName2 = "n";
          this.doRequestAds();
        }
      }
      else //게시물일경우에는 images
      {
        if (this.snsInfo && IsValidS(this.snsInfo.images))
        {
          this.thumnailImageName1 = this.snsInfo.images[0];
          this.thumnailImageName2 = "n";
          await this.doUploadThumnail();
        }
        else
        {
          this.thumnailImageName2 = "n";
          this.doRequestAds();
        }
      }
      return;
    }
    else
    {
      alert(`주문하실 플랫폼을 올바르게 선택해 주세요.`);
      return;
    }

    //this.setLoading(true);
    //alert(`준비중입니다.`);
  }

  //썸네일 이미지를 서버에 업로드 한다.
  doUploadThumnail = async () =>
  {
    if (LOG_ENABLE)
      console.log(`doUploadThumnail : ${this.thumnailImageName1}`);

    try
    {
      this.setLoading(true);

      const newFile = await UploadFileUrl(this.thumnailImageName1);

      if (!IsValidS(newFile))
      {
        if (LOG_ENABLE)
          console.log(`UploadFileUrl failed1`);  

        this.thumnailImageName2 = "n";
        this.doRequestAds();
      }
      else
      {
        if (LOG_ENABLE)
          console.log(`UploadFileUrl success : ${newFile}`);  

        this.thumnailImageName1 = newFile;
        await this.resizeThumnail(newFile); 
      }
    }
    catch(e)
    {
      if (LOG_ENABLE)
        console.log(`thumnail download failed : ${e.toString()}`);

      //실패인경우
      this.thumnailImageName2 = "n";
      this.doRequestAds();
    }
  }

  resizeThumnail = async (name) =>
  {
    //먼저 썸네일 이미지를 원본에서 다운로드한다.

    const imageBlob = await DownloadImageBlob(GetTempImgUrl(this.thumnailImageName1));

    if (!imageBlob) //실패인경우
    {
      this.thumnailImageName2 = "n";
      this.doRequestAds();
      return;
    }
    else
    {
      //다운로드된 이미지를 적당한 크기로 조정한다.
      //const tempFile = ConvertImageBlobToFile(imageBlob, "thumnail");
      const tempFile = imageBlob;//

      if (!tempFile)
      {
        this.thumnailImageName2 = "n";
        this.doRequestAds();
        return;
      }
      else
      {
        tempFile.name=name;
        tempFile.originalname=name;
        this.imageFile = await ResizeImage(tempFile, "thumnail", 1, 200, /*tempFile.type*/"image/*");

        if (LOG_ENABLE)
          console.log(`converted image : ${JSON.stringify(this.imageFile)}`);

        if (!this.imageFile)
        {
          this.thumnailImageName2 = "n";
          this.doRequestAds();
          return;
        }
        else
        {//서버에 변환된 이미지 파일을 업로드 하기
          this.imageFile.originalname=name;

          let fileExt = null;

          if (IsValidS(name) && name.indexOf(".") >= 0)
          {
            const temp = name.split(".");

            if (temp && temp.length >= 2)
            {
              fileExt = temp[temp.length - 1];
            }
          }

          UploadFileWithExt(6, this.imageFile, fileExt, (success, oldName, newName) => {
            if (LOG_ENABLE)
              console.log(`upload result : ${success}/${oldName}/${newName}`);

            this.thumnailImageName2 = success ? newName : "n";
            this.doRequestAds();
          });
        }
      }
    }
  }


  //광고를 신청한다.
  doRequestAds = () =>
  {
    if (LOG_ENABLE)
      console.log(`doRequestAds`);

    if (!IsValidS(this.thumnailImageName2) || this.thumnailImageName2 === "n")
      this.thumnailImageName2 = "defimg.jpg";

    this.setLoading(true);
    const adsType = getAdsTypeCode(this.state.platform, this.state.adsType);

    AdsStoreInstance.requestAds(this.state.platform, adsType, this.state.genderType, this.state.ageType, 
      this.state.link, this.state.count, this.state.repeatDelay, this.state.repeatCount,
      this.state.basePrice + this.state.genderPrice + this.state.agePrice,
      this.state.userPoint, this.state.partnerPoint, this.thumnailImageName2, this.onNewAdsResult);
  }

  onNewAdsResult = (resultCode) =>
  {
    this.setLoading(false);

    switch(resultCode)
    {
      case 0:
        alert(`신규 광고가 정상 등록되었습니다.\n등록된 광고는 내부 시스템 처리 과정을 거친 후 노출이 시작되며, 광고 물량이 많을경우 일정 시간 시작이 지연될 수 있습니다.`);
        this.clearInfo();
        return;
      case 1:
        alert(`잘못된 요청입니다.`);
        return;
      case 2:
        alert(`프로필 조회 중 오류가 발생했습니다.`);
        return;
      case 3:
        alert(`포인트가 부족합니다.`);
        return;
      case 4:
        alert(`SNS 링크 주소가 너무 깁니다.\n최대 298자까지 입력 가능합니다.`);
        return;
      case 5:
        alert(`광고 해시 등록에 실패 했습니다.`);
        return;
      case 6:
        alert(`SNS 플랫폼이 올바르지 않습니다.`);
        return;
      case 7:
        alert(`광고 타입이 올바르지 않습니다.`);
        return;
      case 8:
        alert(`성별 또는 나이대가 올바르지 않습니다.`);
        return;
      case 9:
        alert(`반복 지연 시간이 올바르지 않습니다.`);
        return;
      case 11:
        alert(`서버점검이 예약되어 있어 지금은 신규 광고를 등록할 수 없습니다.`);
        return;
      case 12:
        alert(getAdsSystemMaintMsg(this.state.platform, this.state.adsType));
        return;
      default:
        alert(`광고 등록 중 오류가 발생했습니다.\n잠시 후 이용해 주세요.`);
        return;
    }
  }

  clearInfo = () =>
  {
    //this.setPlatform(0);
    //this.setAdsType(0);
    //this.setAgeType(0);
    //this.setGenderType(0);
    this.setLink('');
    this.setCount(0);
    this.setRepeat(0);
    this.setRepeatCount(0);
    this.setRepeatDelay(0);
    this.setSnsChecked(false);
    this.calcPrice(this.state.platform, this.state.adsType, this.state.genderType, this.state.ageType, 0, 0);
  }

  //sns주소 확인하기
  handleCheckSns = () =>
  {
    if (LOG_ENABLE)
      console.log(`handleCheckSns`);

    if (this.state.loading)
      return;

    if (!IsValidS(this.state.link))
    {
      alert(`게시할 SNS주소를 입력해 주세요.`);
      return;
    }

    this.setSnsChecked(false);

    let isPost = false;

    switch(this.state.platform)
    {
      case 0: //인스타
      case 2: //유튜브
      case 3: //틱톡
        isPost = !(this.state.adsType === 1);
        break;
      case 1: //페북
        isPost = !(this.state.adsType === 1 || this.state.adsType === 3);
        break;
      default:
        break;
    }

    if (!checkSnsAddress(this.state.platform, isPost ? 1 : 0, this.state.link))
    {
      alert(`sns 주소 형식이 올바르지 않습니다.`);
      return;
    }

    //프로필카드 주소면
    if (this.state.platform === 0 && checkInstaPfCardAddress(this.state.link))
    {
      alert(`sns 주소 형식이 올바르지 않습니다.\n프로필 카드 주소는 사용이 불가능합니다.\n프로필 페이지 주소를 입력해 주세요.`);
      return;
    }

    switch(this.state.platform)
    {
      case 0: //인스타
        if (INSTAGRAM_CHECK_MODE === 0) //sns스크랩핑 서버 처리방식인경우
          SNSQueryInstance.instagramQuery(this.state.adsType === 1 ? 0 : 1, this.state.link, this.onSnsQueryResult);
        else //백그라운드 스크랩핑 서비스 처리 방식인경우
        {
          this.setSnsChecked(true);
          this.snsInfo = null;
          return;
        }
        break;
      case 1: //페북
        //프로필 또는 페이지 팔로우인경우
        SNSQueryInstance.facebookQuery((this.state.adsType === 1 || this.state.adsType === 3) ? 0 : 1, this.state.link, this.onSnsQueryResult);
        break;
      case 2: //유튜브
        SNSQueryInstance.youtubeQuery(this.state.adsType === 1 ? 0 : 1, this.state.link, this.onSnsQueryResult);
        break;
      case 3: //틱톡
        SNSQueryInstance.tiktokQuery(this.state.adsType === 1 ? 0 : 1, this.state.link, this.onSnsQueryResult);
        break;
      default:
        return;
    }

    this.setLoading(true);
  }

  onSnsQueryResult = (success, result) =>
  {
    this.setLoading(false);

    if (!success || !result)
    {
      alert('입력하신 SNS주소가 올바르지 않습니다.\nSNS주소를 확인해 주세요.');
      return;
    }

    if (!result.success)
    {
      alert('입력하신 SNS주소가 올바르지 않습니다.\nSNS주소를 확인해 주세요.');
      return;
    }

    this.setSnsChecked(true);

    this.snsInfo = result;

    console.log(`SNS조회결과 : ${JSON.stringify(result)}`);
  }
  
  handleTalk = () =>
  {
    gotoTalkPage();
  }

  //모바일 버전, 주문가이드 눌림
  handleHelp = () =>
  {
    if (LOG_ENABLE)
      console.log(`handleHelp`);

    this.setShowGuidePop(true);
  }

  //URL입력창에서 엔터키 눌림
  handleUrlEnter = () =>
  {
    if (LOG_ENABLE)
      console.log(`handleUrlEnter`);
  }

  renderPc = () =>
  {
    return (
      <div className='pgcont coltc'>
        {
          this.state.showLogin ? (<Login handleLoginEvent={this.handleLoginEvent}/>) : null
        }
        {
          this.state.showFindId ? (<FindId handleRegister={this.handleRegister} handleClose={() => this.showFindId(false)} handleFindPw={this.handleFindPw} handleLogin={this.handleFindIdLogin}/>) : null
        }
        {
          this.state.showFindPw ? (<FindPw handleClose={() => this.showFindPw(false)} handleRegister={this.handleRegister} />) : null
        }
        {
          this.state.showMobileMenu ? (<MobileMenu handleNavi={this.handleNavi} handleMenu={this.handleMenu} />) : null
        }
        {
          this.state.showTerm ?(<Term handleClose={() => this.showTerm(false)}/>) : null
        }
        {
          this.state.showPrivacy ? (<Privacy handleClose={() => this.showPrivacy(false)}/>) : null
        }
        {
          this.state.loading ? (<Loading />) : null
        }
        <Talk handleTalk={this.handleTalk}/>
        <TopBar handleNavi={this.handleNavi} handleMenu={this.handleMenu} selected={0} withLine={true}/>

        <div className='pgcont2 rowlt'>
          <NaviBar width={this.state.naviWidth} height={this.state.height} handleSnsOrder={this.handleSnsOrder} handleNavi={this.handleNavi} handleMenu={this.handleMenu}/>

          <div className='homecont colel' style={{width: this.state.contWidth, height: this.state.height}}>
            <div className='homecont2 coltl' style={{width: this.state.contWidth - 40 - 50, height: this.state.height - 100}}>
              <label className='font20 wt600 color31 mgtop20'>SNS 팔로우 주문하기</label>
              <div className='wd100p rowlt mgtop20'>
                <OrderRight
                  snsLinkChecked={this.state.snsChecked}
                  platform={this.state.platform}
                  adsType={this.state.adsType}
                  genderType={this.state.genderType}
                  ageType={this.state.ageType}
                  link={this.state.link}
                  count={this.state.count}
                  repeat={this.state.repeat}
                  repeatCount={this.state.repeatCount}
                  repeatDelay={this.state.repeatDelay}
                  handlePlatform={(v) => this.handleAdsSelection(0, v)}
                  handleAdsType={(v) => this.handleAdsSelection(1, v)}
                  handleAgeType={(v) => this.handleAdsSelection(2, v)}
                  handleGenderType={(v) => this.handleAdsSelection(3, v)}
                  handleLink={(v) => this.handleAdsSelection(4, v)}
                  handleCount={(v) => this.handleAdsSelection(5, v)}
                  handleRepeat={(v) => this.handleAdsSelection(6, v)}
                  handleRepeatData={(index, v) => this.handleAdsSelection(index, v)}
                  handleOrder={this.handleOrder}
                  handleEnter={this.handleUrlEnter}
                  handleCheckSns={this.handleCheckSns}
                  getBaseCost={this.getBaseCost}
                  snsChecked={this.state.snsChecked}

                  baseCost={this.state.basePrice}
                  genderCost={this.state.genderPrice}
                  ageCost={this.state.agePrice}
                  resultCost={this.state.resultPrice}
                  adsConfig={this.state.adsConfig}
                />
                <OrderLeft platform={this.state.platform} adsType={this.state.adsType} height={this.state.height - 100 - 70}/>
              </div>
            </div>

            <FooterBar handleMenu={this.handleMenu} />
          </div>
        </div>

        
      </div>
    );
  }

  renderMobile = () =>
  {
    return (
      <div className='orderbg flexcoltc'>
        {
          this.state.showMobileMenu ? (<MobileMenu handleNavi={this.handleNavi} handleMenu={this.handleMenu} userPoint={LoginStoreInstance.getUserPoint} />) : null
        }
        {
          this.state.showGuidePop ? (<OrderDescPop platform={this.state.platform} adsType={this.state.adsType} handleClose={() => this.setShowGuidePop(false)}/>) : null
        }
        {
          this.state.showTerm ?(<Term handleClose={() => this.showTerm(false)}/>) : null
        }
        {
          this.state.showPrivacy ? (<Privacy handleClose={() => this.showPrivacy(false)}/>) : null
        }
        {
          this.state.loading ? (<Loading />) : null
        }
        <Talk handleTalk={this.handleTalk}/>
        <TopBar handleNavi={this.handleNavi} handleMenu={this.handleMenu} selected={0} withLine={true}/>
        
        <div className='ordercont_mb flexcoltc marginbottom70'>
          <OrderRightMb
            snsLinkChecked={this.state.snsChecked}
            platform={this.state.platform}
            adsType={this.state.adsType}
            genderType={this.state.genderType}
            ageType={this.state.ageType}
            link={this.state.link}
            count={this.state.count}
            repeat={this.state.repeat}
            repeatCount={this.state.repeatCount}
            repeatDelay={this.state.repeatDelay}
            handlePlatform={(v) => this.handleAdsSelection(0, v)}
            handleAdsType={(v) => this.handleAdsSelection(1, v)}
            handleAgeType={(v) => this.handleAdsSelection(2, v)}
            handleGenderType={(v) => this.handleAdsSelection(3, v)}
            handleLink={(v) => this.handleAdsSelection(4, v)}
            handleCount={(v) => this.handleAdsSelection(5, v)}
            handleRepeat={(v) => this.handleAdsSelection(6, v)}
            handleRepeatData={(index, v) => this.handleAdsSelection(index, v)}
            handleOrder={this.handleOrder}
            handleHelp={this.handleHelp}
            handleEnter={this.handleUrlEnter}
            handleCheckSns={this.handleCheckSns}
            getBaseCost={this.getBaseCost}
            snsChecked={this.state.snsChecked}
            baseCost={this.state.basePrice}
            genderCost={this.state.genderPrice}
            ageCost={this.state.agePrice}
            resultCost={this.state.resultPrice}
            adsConfig={this.state.adsConfig}
          />
        </div>

        <FooterBar handleMenu={this.handleMenu} />
      </div>
    );
  }

  render()
  {
    /*if (mobileOn)
    {
      return this.renderMobile();
    }
    else*/
    {
      return this.renderPc();
    }
  }
}

export default withNavigation(observer(Order));