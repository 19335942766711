import './App.css';
import { HashRouter, Route, Routes,  } from 'react-router-dom';
import Register from './Components/Register/Register';
import Setting from './Components/Setting/Setting';
import Faq from './Components/Faq/Faq';
import Notice from './Components/Notice/Notice';
import OrderList from './Components/OrderList/OrderList';
import Purchase from './Components/Purchase/Purchase';
import Order from './Components/Order/Order';
import UnregPage from './Components/Unregister/UnregPage';
import Home from './Components/Home/Home';
import Bingo from './Components/Bingo/Bingo';

function App() {

  return (
    <HashRouter>
      <Routes>
        <Route exact path="/" element={<Home/>} />
        <Route exact path="/register" element={<Register/>} />
        <Route exact path="/setting" element={<Setting/>} />
        <Route exact path="/faq" element={<Faq/>} />
        <Route exact path="/notice" element={<Notice/>} />
        <Route exact path="/orderlist" element={<OrderList/>} />
        <Route exact path="/purchase" element={<Purchase/>} />
        <Route exact path="/order" element={<Order/>} />
        <Route exact path="/unreg" element={<UnregPage/>} />
        <Route exact path="/bingo" element={<Bingo/>} />
      </Routes>
    </HashRouter>
  );
}

export default App;
