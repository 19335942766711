import ExcelJS from 'exceljs';
import { checkVideoBingo, getBingoAgeInfo, getBingoGameTypeCost, getBingoGameTypeName, getBingoGenderInfo, GetFormattedNum } from '../../Util/Util';
import { LOG_ENABLE } from '../../URL/AppUrl';

const printBingoReport = (order, report, callback) =>
{
  const workBook = new ExcelJS.Workbook();
  workBook.creator = 'Bravo6';
  workBook.created = new Date();
  workBook.modified = new Date();

  let sheet1 = workBook.addWorksheet('빙고 팡팡 성과', {properties:{tabColor:{argb:'FFFC0000'}, outlineLevelCol:1}});

  makeSheetBingoReport(order, report, sheet1);

  workBook.xlsx.writeBuffer().then(function (buffer){
    if (callback)
      callback(new Blob([buffer]));
  });
}

const getColumnWidth = (index) =>
{
  const temp = [2.63, 3.88, 8.38, 23.63, 36.38, 22.13, 19.5, 20.38, 20.38, 20.38, 16.75, 15.63, 13.38, 13.38, 15.88, 15.88, 
    8.38, 8.38, 8.38, 8.38, 8.38, 8.38, 12.63, 12.63, 8.38, 8.38, 8.38, 8.38, 8.38, 8.38, 8.38, 8.38, 8.38, 8.38, 
    15.75, 15.75
  ];

  return temp[index];
}

const getBingoDateRange = (report) =>
{
  if (!report)
    return "";

  if (report.edt === 0)
    return report.sdt.toString();

  return `${report.sdt}-${report.edt}`;
}

const getTotalPlayCount = (report) =>
{
  if (!report)
    return "";

  let total = 0;

  if (report.playInfo1)
  {
    total += report.playInfo1.p1;
    total += report.playInfo1.p2 * 2;
    total += report.playInfo1.p3 * 3;
    total += report.playInfo1.p4 * 4;
    total += report.playInfo1.p5 * 5;
  }

  if (report.playInfo2)
  {
    total += report.playInfo2.p6 * 6;
    total += report.playInfo2.p7 * 7;
    total += report.playInfo2.p8 * 8;
    total += report.playInfo2.p9 * 9;
    total += report.playInfo2.p10 * 10;
    total += report.playInfo2.p11 * 11;
    total += report.playInfo2.p12 * 12;
    total += report.playInfo2.p13 * 13;
    total += report.playInfo2.p14 * 14;
    total += report.playInfo2.p15 * 15;
  }

  if (report.playInfo3)
  {
    total += report.playInfo3.p16 * 16;
    total += report.playInfo3.p17 * 17;
    total += report.playInfo3.p18 * 18;
    total += report.playInfo3.p19 * 19;
    total += report.playInfo3.p20 * 20;
    total += report.playInfo3.p21 * 21;
    total += report.playInfo3.p22 * 22;
    total += report.playInfo3.p23 * 23;
    total += report.playInfo3.p24 * 24;
    total += report.playInfo3.p25 * 25;
  }

  return GetFormattedNum(total);
}

const getGameTypeAgeGender = (order) =>
{
  let temp = order.gt === 1 ? "쇼츠형_" : "일반형_";
  
  temp += getBingoAgeInfo(order);
  temp += `_${getBingoGenderInfo(order.gtt)}`;

  return temp;
}

const getBingoTotalMan = (report) =>
{
  if (!report)
    return 0;

  let total = 0;

  if (report.ageInfo)
  {
    total += report.ageInfo.mag10;
    total += report.ageInfo.mag20;
    total += report.ageInfo.mag30;
    total += report.ageInfo.mag40;
    total += report.ageInfo.mag50;
  }

  return total;
}

const getBingoTotalWoman = (report) =>
{
  if (!report)
    return 0;

  let total = 0;

  if (report.ageInfo)
  {
    total += report.ageInfo.fag10;
    total += report.ageInfo.fag20;
    total += report.ageInfo.fag30;
    total += report.ageInfo.fag40;
    total += report.ageInfo.fag50;
  }

  return total;
}

const getBingoPer = (cnt, total) =>
{
  if (total < 1)
    return "";

  return `${((cnt / total) * 100).toFixed(2)}%`;
}

const makeSheetBingoReport = (order, report, sheet) =>
{
  const col = [];
  for (let i = 0;i < 36; ++i)
  {
    col.push({
      key: `col${i}`,
      width: getColumnWidth(i)
    });
  }

  sheet.columns = col;

  const border = {
    top: {style:'thin'},
    left: {style:'thin'},
    bottom: {style:'thin'},
    right: {style:'thin'}
  };
    
  const fill1 = {
    type: 'pattern',
    pattern:'solid',
    fgColor:{argb:'ffd9e1f2'},
  };
  
  const fill2 = {
    type: 'pattern',
    pattern:'solid',
    fgColor:{argb:'ffa9d08e'},
  };

  const boldFont = {bold : true, color : {argb: 'ff1e1e1e'}};
  const centerAlign = {vertical: 'middle', horizontal: 'center'};;

  sheet.mergeCells('A3:E3');
  setCellData(sheet.getCell('A3'), "1. 기본지표", boldFont, centerAlign, null, border);

  sheet.mergeCells('A4:A7');
  sheet.mergeCells('A11:A14');
  sheet.mergeCells('A18:A23');

  sheet.mergeCells('B4:B5');
  sheet.mergeCells('C4:C5');
  sheet.mergeCells('D4:D5');
  sheet.mergeCells('E4:E6');
  sheet.mergeCells('F4:F6');
  sheet.mergeCells('G4:G6');
  sheet.mergeCells('H4:H6');
  sheet.mergeCells('I4:I6');
  sheet.mergeCells('J4:J6');
  sheet.mergeCells('K4:L5');
  sheet.mergeCells('M4:M6');
  sheet.mergeCells('N4:N6');
  sheet.mergeCells('O4:P5');

  sheet.mergeCells('B6:B7');
  sheet.mergeCells('C6:C7');
  sheet.mergeCells('D6:D7');

  setCellStyle(sheet.getCell('B4'), null, null, null, border);
  setCellStyle(sheet.getCell('B6'), null, null, null, border);
  setCellStyle(sheet.getCell('C6'), null, null, null, border);
  setCellStyle(sheet.getCell('D6'), null, null, null, border);

  setCellStyle(sheet.getCell('C4'), null, null, fill1, border);
  setCellData(sheet.getCell('D4'), "기본 지표", boldFont, centerAlign, fill1, border);

  setCellData(sheet.getCell('E4'), "광고 제목", boldFont, centerAlign, fill1, border);
  setCellData(sheet.getCell('F4'), "게시 일자", boldFont, centerAlign, fill1, border);
  setCellData(sheet.getCell('G4'), "지출 금액", boldFont, centerAlign, fill1, border);
  
  setCellData(sheet.getCell('H4'), "GB노출수", boldFont, centerAlign, fill2, border);
  setCellData(sheet.getCell('I4'), "SB노출수", boldFont, centerAlign, fill2, border);
  setCellData(sheet.getCell('J4'), "BB노출수", boldFont, centerAlign, fill2, border);
  
  setCellData(sheet.getCell('K4'), "총 노출과 CPM", boldFont, centerAlign, fill1, border);
  setCellData(sheet.getCell('K6'), "총노출수", boldFont, centerAlign, fill1, border);
  setCellData(sheet.getCell('L6'), "CPM", boldFont, centerAlign, fill1, border);

  setCellData(sheet.getCell('M4'), "LB클릭수", boldFont, centerAlign, fill2, border);
  setCellData(sheet.getCell('N4'), "RB클릭수", boldFont, centerAlign, fill2, border);

  setCellData(sheet.getCell('O4'), "총 링크 클릭", boldFont, centerAlign, fill1, border);
  setCellData(sheet.getCell('O6'), "총클릭수", boldFont, centerAlign, fill1, border);
  setCellData(sheet.getCell('P6'), "CPC", boldFont, centerAlign, fill1, border);

  setCellData(sheet.getCell('D6'), getBingoGameTypeName(order.gt), boldFont, centerAlign, null, border);

  const usedCost = report.spcnt * getBingoGameTypeCost(order.gt);

  if (LOG_ENABLE)
    console.log(`used cost = ${usedCost}`);

  setCellData(sheet.getCell('E7'), order.tt, boldFont, centerAlign, null, border);
  setCellData(sheet.getCell('F7'), getBingoDateRange(report), boldFont, centerAlign, null, border); //게시일자
  setCellData(sheet.getCell('G7'), GetFormattedNum(order.pamt), boldFont, centerAlign, null, border); //지출금액
  setCellData(sheet.getCell('H7'), report ? GetFormattedNum(report.stcnt) : "", boldFont, centerAlign, null, border); //gb노출수, 게임시작횟수
  setCellData(sheet.getCell('I7'), report ? GetFormattedNum(report.spcnt) : "", boldFont, centerAlign, null, border); //sb노출수, 스핀버튼클릭수
  setCellData(sheet.getCell('J7'), report ? GetFormattedNum(report.spcnt) : "", boldFont, centerAlign, null, border); //bb노출수, 스핀버튼클릭수
  setCellData(sheet.getCell('K7'), report ? GetFormattedNum(report.stcnt + report.spcnt + report.spcnt) : "", boldFont, centerAlign, null, border); //총 노출수
  setCellData(sheet.getCell('L7'), report ? `₩${((usedCost / (report.stcnt + report.spcnt + report.spcnt)) * 1000.0).toFixed(2)}` : "", boldFont, centerAlign, null, border); //cpm
  setCellData(sheet.getCell('M7'), report ? GetFormattedNum(report.lbcnt) : "", boldFont, centerAlign, null, border); //lb클릭수
  setCellData(sheet.getCell('N7'), report ? GetFormattedNum(report.rbcnt) : "", boldFont, centerAlign, null, border); //rb클릭수
  setCellData(sheet.getCell('O7'), report ? GetFormattedNum(report.lbcnt + report.rbcnt) : "", boldFont, centerAlign, null, border); //총클릭수
  setCellData(sheet.getCell('P7'), report ? `₩${(usedCost / (report.lbcnt + report.rbcnt)).toFixed(2)}` : "", boldFont, centerAlign, null, border); //cpc

  //추가지표
  sheet.mergeCells('A10:E10');

  setCellData(sheet.getCell('A10'), "2. 추가 지표 노출 빈도", boldFont, centerAlign, null, border);

  if (!checkVideoBingo(order.gt))
  {
    sheet.mergeCells('B11:B12');
    sheet.mergeCells('B13:B14');
    sheet.mergeCells('C11:C12');
    sheet.mergeCells('C13:C14');
    sheet.mergeCells('D11:D12');
    sheet.mergeCells('D13:D14');
    sheet.mergeCells('E11:E13');

    sheet.mergeCells('F11:L11');
    sheet.mergeCells('F12:J12');
    sheet.mergeCells('K12:K13');
    sheet.mergeCells('L12:L13');
    setCellStyle(sheet.getCell('B11'), null, null, null, border);
    setCellStyle(sheet.getCell('B13'), null, null, null, border);
    setCellStyle(sheet.getCell('C11'), null, null, fill1, border);
    setCellStyle(sheet.getCell('C13'), null, null, null, border);
    setCellData(sheet.getCell('D11'), "기본 지표", boldFont, centerAlign, fill1, border);
    setCellData(sheet.getCell('E11'), "노출 빈도", boldFont, centerAlign, fill1, border);
    setCellData(sheet.getCell('F11'), "플레이 지표", boldFont, centerAlign, fill1, border);
    setCellData(sheet.getCell('F12'), "플레이 횟수", boldFont, centerAlign, fill1, border);
    setCellData(sheet.getCell('F13'), "1플레이", boldFont, centerAlign, fill1, border);
    setCellData(sheet.getCell('G13'), "2플레이", boldFont, centerAlign, fill1, border);
    setCellData(sheet.getCell('H13'), "3플레이", boldFont, centerAlign, fill1, border);
    setCellData(sheet.getCell('I13'), "4플레이", boldFont, centerAlign, fill1, border);
    setCellData(sheet.getCell('J13'), "5플레이", boldFont, centerAlign, fill1, border);
    
    setCellData(sheet.getCell('K12'), "총 플레이 수", boldFont, centerAlign, fill1, border);
    setCellData(sheet.getCell('L12'), "플레이 유저 수", boldFont, centerAlign, fill1, border);
  
    setCellData(sheet.getCell('D13'), getBingoGameTypeName(order.gt), boldFont, centerAlign, null, border);
    setCellData(sheet.getCell('E14'), getGameTypeAgeGender(order), boldFont, centerAlign, null, border);
    setCellData(sheet.getCell('F14'), report && report.pl1 ? GetFormattedNum(report.playInfo1.p1) : "", boldFont, centerAlign, null, border);
    setCellData(sheet.getCell('G14'), report && report.pl1 ? GetFormattedNum(report.playInfo1.p2) : "", boldFont, centerAlign, null, border);
    setCellData(sheet.getCell('H14'), report && report.pl1 ? GetFormattedNum(report.playInfo1.p3) : "", boldFont, centerAlign, null, border);
    setCellData(sheet.getCell('I14'), report && report.pl1 ? GetFormattedNum(report.playInfo1.p4) : "", boldFont, centerAlign, null, border);
    setCellData(sheet.getCell('J14'), report && report.pl1 ? GetFormattedNum(report.playInfo1.p5) : "", boldFont, centerAlign, null, border);
    setCellData(sheet.getCell('K14'), getTotalPlayCount(report), boldFont, centerAlign, null, border); //총플레이수
    setCellData(sheet.getCell('L14'), report ? GetFormattedNum(report.pucnt) : "", boldFont, centerAlign, null, border); //플레이유저수
  }
  else //3x3형
  {
    sheet.mergeCells('B11:B12');
    sheet.mergeCells('B13:B14');
    sheet.mergeCells('C11:C12');
    sheet.mergeCells('C13:C14');
    sheet.mergeCells('D11:D12');
    sheet.mergeCells('D13:D14');
    sheet.mergeCells('E11:E13');
    
    sheet.mergeCells('F11:AJ11');
    sheet.mergeCells('F12:AH12');

    sheet.mergeCells('AI12:AI13');
    sheet.mergeCells('AJ12:AJ13');

    setCellStyle(sheet.getCell('B11'), null, null, null, border);
    setCellStyle(sheet.getCell('B13'), null, null, null, border);
    setCellStyle(sheet.getCell('C11'), null, null, fill1, border);
    setCellStyle(sheet.getCell('C13'), null, null, null, border);

    setCellData(sheet.getCell('D11'), "기본 지표", boldFont, centerAlign, fill1, border);
    setCellData(sheet.getCell('E11'), "노출 빈도", boldFont, centerAlign, fill1, border);
    setCellData(sheet.getCell('F11'), "플레이 지표", boldFont, centerAlign, fill1, border);
    setCellData(sheet.getCell('F12'), "플레이 횟수", boldFont, centerAlign, fill1, border);

    setCellData(sheet.getCell('AI12'), "총 플레이 수", boldFont, centerAlign, fill1, border);
    setCellData(sheet.getCell('AJ12'), "플레이 유저 수", boldFont, centerAlign, fill1, border);

    const temp = ['F', 'G', 'H', 'I', 'J', 'K', 'L', 'M', 'N', 'O', 'P', 'Q', 'R', 'S', 'T', 'U', 'V', 'W', 'X', 'Y', 'Z', 'AA', 'AB', 'AC', 'AD', 'AE', 'AF', 'AG', 'AH'];

    let count = 1;
    for (let i = 0;i < temp.length; ++i)
    {
      switch(i)
      {
        case 5:
        case 17:
          setCellData(sheet.getCell(`${temp[i]}13`), "ADV\n시청시작", boldFont, centerAlign, fill1, border);
          break;
        case 6:
        case 18:
          setCellData(sheet.getCell(`${temp[i]}13`), "ADV\n시청완료", boldFont, centerAlign, fill1, border);
          break;
        default:
          setCellData(sheet.getCell(`${temp[i]}13`), `${count}플레이`, boldFont, centerAlign, fill1, border);
          ++count;
          break;
      }
    }

    setCellData(sheet.getCell(`D13`), getBingoGameTypeName(order.gt), boldFont, centerAlign, null, border);
    setCellData(sheet.getCell(`E14`), getGameTypeAgeGender(order), boldFont, centerAlign, null, border);
    
    setCellData(sheet.getCell(`${temp[0]}14`), report && report.playInfo1 ? GetFormattedNum(report.playInfo1.p1) : "", boldFont, centerAlign, null, border); //1플레이
    setCellData(sheet.getCell(`${temp[1]}14`), report && report.playInfo1 ? GetFormattedNum(report.playInfo1.p2) : "", boldFont, centerAlign, null, border); //2플레이
    setCellData(sheet.getCell(`${temp[2]}14`), report && report.playInfo1 ? GetFormattedNum(report.playInfo1.p3) : "", boldFont, centerAlign, null, border); //3플레이
    setCellData(sheet.getCell(`${temp[3]}14`), report && report.playInfo1 ? GetFormattedNum(report.playInfo1.p4) : "", boldFont, centerAlign, null, border); //4플레이
    setCellData(sheet.getCell(`${temp[4]}14`), report && report.playInfo1 ? GetFormattedNum(report.playInfo1.p5) : "", boldFont, centerAlign, null, border); //5플레이
    setCellData(sheet.getCell(`${temp[5]}14`), report ? GetFormattedNum(report.mscnt1) : "", boldFont, centerAlign, null, border); //시청시작
    setCellData(sheet.getCell(`${temp[6]}14`), report ? GetFormattedNum(report.mecnt1) : "", boldFont, centerAlign, null, border); //시청종료
    setCellData(sheet.getCell(`${temp[7]}14`), report && report.playInfo2 ? GetFormattedNum(report.playInfo2.p6) : "", boldFont, centerAlign, null, border); //6플레이
    setCellData(sheet.getCell(`${temp[8]}14`), report && report.playInfo2 ? GetFormattedNum(report.playInfo2.p7) : "", boldFont, centerAlign, null, border); //7플레이
    setCellData(sheet.getCell(`${temp[9]}14`), report && report.playInfo2 ? GetFormattedNum(report.playInfo2.p8) : "", boldFont, centerAlign, null, border); //8플레이
    setCellData(sheet.getCell(`${temp[10]}14`), report && report.playInfo2 ? GetFormattedNum(report.playInfo2.p9) : "", boldFont, centerAlign, null, border); //9플레이
    setCellData(sheet.getCell(`${temp[11]}14`), report && report.playInfo2 ? GetFormattedNum(report.playInfo2.p10) : "", boldFont, centerAlign, null, border); //10플레이
    setCellData(sheet.getCell(`${temp[12]}14`), report && report.playInfo2 ? GetFormattedNum(report.playInfo2.p11) : "", boldFont, centerAlign, null, border); //11플레이
    setCellData(sheet.getCell(`${temp[13]}14`), report && report.playInfo2 ? GetFormattedNum(report.playInfo2.p12) : "", boldFont, centerAlign, null, border); //12플레이
    setCellData(sheet.getCell(`${temp[14]}14`), report && report.playInfo2 ? GetFormattedNum(report.playInfo2.p13) : "", boldFont, centerAlign, null, border); //13플레이
    setCellData(sheet.getCell(`${temp[15]}14`), report && report.playInfo2 ? GetFormattedNum(report.playInfo2.p14) : "", boldFont, centerAlign, null, border); //14플레이
    setCellData(sheet.getCell(`${temp[16]}14`), report && report.playInfo2 ? GetFormattedNum(report.playInfo2.p15) : "", boldFont, centerAlign, null, border); //15플레이
    setCellData(sheet.getCell(`${temp[17]}14`), report ? GetFormattedNum(report.mscnt2) : "", boldFont, centerAlign, null, border); //시청시작
    setCellData(sheet.getCell(`${temp[18]}14`), report ? GetFormattedNum(report.mecnt2) : "", boldFont, centerAlign, null, border); //시청종료
    setCellData(sheet.getCell(`${temp[19]}14`), report && report.playInfo3 ? GetFormattedNum(report.playInfo3.p16) : "", boldFont, centerAlign, null, border); //16플레이
    setCellData(sheet.getCell(`${temp[20]}14`), report && report.playInfo3 ? GetFormattedNum(report.playInfo3.p17) : "", boldFont, centerAlign, null, border); //17플레이
    setCellData(sheet.getCell(`${temp[21]}14`), report && report.playInfo3 ? GetFormattedNum(report.playInfo3.p18) : "", boldFont, centerAlign, null, border); //18플레이
    setCellData(sheet.getCell(`${temp[22]}14`), report && report.playInfo3 ? GetFormattedNum(report.playInfo3.p19) : "", boldFont, centerAlign, null, border); //19플레이
    setCellData(sheet.getCell(`${temp[23]}14`), report && report.playInfo3 ? GetFormattedNum(report.playInfo3.p20) : "", boldFont, centerAlign, null, border); //20플레이
    setCellData(sheet.getCell(`${temp[24]}14`), report && report.playInfo3 ? GetFormattedNum(report.playInfo3.p21) : "", boldFont, centerAlign, null, border); //21플레이
    setCellData(sheet.getCell(`${temp[25]}14`), report && report.playInfo3 ? GetFormattedNum(report.playInfo3.p22) : "", boldFont, centerAlign, null, border); //22플레이
    setCellData(sheet.getCell(`${temp[26]}14`), report && report.playInfo3 ? GetFormattedNum(report.playInfo3.p23) : "", boldFont, centerAlign, null, border); //23플레이
    setCellData(sheet.getCell(`${temp[27]}14`), report && report.playInfo3 ? GetFormattedNum(report.playInfo3.p24) : "", boldFont, centerAlign, null, border); //24플레이
    setCellData(sheet.getCell(`${temp[28]}14`), report && report.playInfo3 ? GetFormattedNum(report.playInfo3.p25) : "", boldFont, centerAlign, null, border); //25플레이
    setCellData(sheet.getCell(`AI14`), getTotalPlayCount(report), boldFont, centerAlign, null, border); //총플레이수
    setCellData(sheet.getCell(`AJ14`), report ? GetFormattedNum(report.pucnt) : "", boldFont, centerAlign, null, border); //플레이 유저수
  }

  //성별 및 연령
  sheet.mergeCells('A17:E17');
  
  setCellData(sheet.getCell("A17"), "3. 추가지표_성별연령_타겟팅", boldFont, centerAlign, null, border);

  try
  {
    sheet.mergeCells('B18:B19');
    sheet.mergeCells('B20:B23');
  
    sheet.mergeCells('C18:C19');
    sheet.mergeCells('C20:C23');
    sheet.mergeCells('D18:D23');
  }
  catch(e)
  {
    console.log(e.toString());
  }

  setCellStyle(sheet.getCell('B18'), null, null, null, border);

  setCellData(sheet.getCell("B20"), "", boldFont, centerAlign, null, border);
  setCellData(sheet.getCell("C18"), "매체", boldFont, centerAlign, fill1, border);
  setCellData(sheet.getCell("C20"), "머니팡", boldFont, centerAlign, null, border);
  setCellData(sheet.getCell("D18"), "성별 및 연령", boldFont, centerAlign, fill1, border);

  setCellData(sheet.getCell("E18"), "남자 연령별", boldFont, centerAlign, fill1, border);
  setCellData(sheet.getCell("F18"), "참여수", boldFont, centerAlign, fill1, border);
  setCellData(sheet.getCell("G18"), "비율", boldFont, centerAlign, fill1, border);
  setCellData(sheet.getCell("H18"), "여자 연령별", boldFont, centerAlign, fill1, border);
  setCellData(sheet.getCell("I18"), "참여수", boldFont, centerAlign, fill1, border);
  setCellData(sheet.getCell("J18"), "비율", boldFont, centerAlign, fill1, border);

  setCellData(sheet.getCell("E19"), "남자 10대 이하", boldFont, centerAlign, null, border);
  setCellData(sheet.getCell("E20"), "남자 20대", boldFont, centerAlign, null, border);
  setCellData(sheet.getCell("E21"), "남자 30대", boldFont, centerAlign, null, border);
  setCellData(sheet.getCell("E22"), "남자 40대", boldFont, centerAlign, null, border);
  setCellData(sheet.getCell("E23"), "남자 50대 이상", boldFont, centerAlign, null, border);
  
  setCellData(sheet.getCell("H19"), "여자 10대 이하", boldFont, centerAlign, null, border);
  setCellData(sheet.getCell("H20"), "여자 20대", boldFont, centerAlign, null, border);
  setCellData(sheet.getCell("H21"), "여자 30대", boldFont, centerAlign, null, border);
  setCellData(sheet.getCell("H22"), "여자 40대", boldFont, centerAlign, null, border);
  setCellData(sheet.getCell("H23"), "여자 50대 이상", boldFont, centerAlign, null, border);

  const totalMan = getBingoTotalMan(report);
  const totalWoman = getBingoTotalWoman(report);

  //남자 참여수
  setCellData(sheet.getCell("F19"), report && report.ageInfo ? GetFormattedNum(report.ageInfo.mag10) : "", boldFont, centerAlign, null, border);
  setCellData(sheet.getCell("F20"), report && report.ageInfo ? GetFormattedNum(report.ageInfo.mag20) : "", boldFont, centerAlign, null, border);
  setCellData(sheet.getCell("F21"), report && report.ageInfo ? GetFormattedNum(report.ageInfo.mag30) : "", boldFont, centerAlign, null, border);
  setCellData(sheet.getCell("F22"), report && report.ageInfo ? GetFormattedNum(report.ageInfo.mag40) : "", boldFont, centerAlign, null, border);
  setCellData(sheet.getCell("F23"), report && report.ageInfo ? GetFormattedNum(report.ageInfo.mag50) : "", boldFont, centerAlign, null, border);

  //남자 비율
  setCellData(sheet.getCell("G19"), getBingoPer(report && report.ageInfo ? report.ageInfo.mag10: 0, totalMan), boldFont, centerAlign, null, border);
  setCellData(sheet.getCell("G20"), getBingoPer(report && report.ageInfo ? report.ageInfo.mag20: 0, totalMan), boldFont, centerAlign, null, border);
  setCellData(sheet.getCell("G21"), getBingoPer(report && report.ageInfo ? report.ageInfo.mag30: 0, totalMan), boldFont, centerAlign, null, border);
  setCellData(sheet.getCell("G22"), getBingoPer(report && report.ageInfo ? report.ageInfo.mag40: 0, totalMan), boldFont, centerAlign, null, border);
  setCellData(sheet.getCell("G23"), getBingoPer(report && report.ageInfo ? report.ageInfo.mag50: 0, totalMan), boldFont, centerAlign, null, border);

  //여자 참여수
  setCellData(sheet.getCell("I19"), report && report.ageInfo ? GetFormattedNum(report.ageInfo.fag10) : "", boldFont, centerAlign, null, border);
  setCellData(sheet.getCell("I20"), report && report.ageInfo ? GetFormattedNum(report.ageInfo.fag20) : "", boldFont, centerAlign, null, border);
  setCellData(sheet.getCell("I21"), report && report.ageInfo ? GetFormattedNum(report.ageInfo.fag30) : "", boldFont, centerAlign, null, border);
  setCellData(sheet.getCell("I22"), report && report.ageInfo ? GetFormattedNum(report.ageInfo.fag40) : "", boldFont, centerAlign, null, border);
  setCellData(sheet.getCell("I23"), report && report.ageInfo ? GetFormattedNum(report.ageInfo.fag50) : "", boldFont, centerAlign, null, border);

  //여자 비율
  setCellData(sheet.getCell("J19"), getBingoPer(report && report.ageInfo ? report.ageInfo.fag10: 0, totalWoman), boldFont, centerAlign, null, border);
  setCellData(sheet.getCell("J20"), getBingoPer(report && report.ageInfo ? report.ageInfo.fag20: 0, totalWoman), boldFont, centerAlign, null, border);
  setCellData(sheet.getCell("J21"), getBingoPer(report && report.ageInfo ? report.ageInfo.fag30: 0, totalWoman), boldFont, centerAlign, null, border);
  setCellData(sheet.getCell("J22"), getBingoPer(report && report.ageInfo ? report.ageInfo.fag40: 0, totalWoman), boldFont, centerAlign, null, border);
  setCellData(sheet.getCell("J23"), getBingoPer(report && report.ageInfo ? report.ageInfo.fag50: 0, totalWoman), boldFont, centerAlign, null, border);

  sheet.getRow(7).height = 60;
  sheet.getRow(13).height = 48;
  sheet.getRow(14).height = 56.25;
  //adjustColumnWidth(sheet);
}

const setCellData = (cell, value, font, alignment, fill, border) =>
{
  cell.value = value;

  if (font)
    cell.font = font;

  if (alignment)
    cell.alignment = alignment;

  if (fill)
    cell.fill = fill;

  if (border)
    cell.border = border;
}

const setCellStyle = (cell, font, alignment, fill, border) =>
{
  if (font)
    cell.font = font;

  if (alignment)
    cell.alignment = alignment;

  if (fill)
    cell.fill = fill;

  if (border)
    cell.border = border;
}

export {
  printBingoReport
}